import { HStack, Stack } from "@chakra-ui/react";
import { FiltersPanelAppliedFilters } from "@src/utils/components/filters/FiltersPanelAppliedFilters";
import { FiltersPanelFilters } from "@src/utils/components/filters/FiltersPanelFilters";
import { FiltersPanelSearch } from "@src/utils/components/filters/FiltersPanelSearch";
import { compact, filter, flatten, map } from "lodash";
import { observer } from "mobx-react-lite";
import { ReactNode, createContext, useContext, useMemo } from "react";
import { formatDate } from "../../formatters";
import {
  MultiColumnFilterTypeEnum,
  TableFilter,
  createChoiceListFilter,
  createDateRangeFilters,
  createMultiColumnFilter,
  createPaginatedFilters,
} from "./factories";
import { Filters } from "./models";

// Copied type from shopify Polaris FilterPanel (old implementation)
interface AppliedFilterInterface {
  /** A unique key used to identify the applied filter */
  key: string;
  /** A label for the applied filter */
  label: string;
  /** Callback when the remove button is pressed */
  onRemove(key: string): void;
}

type ContextType<T> = {
  filters: Filters<T>;
  filterComponents: TableFilter[];
  appliedFilters: AppliedFilterInterface[];
};

const FiltersPanelContext = createContext<ContextType<unknown>>({
  filterComponents: [],
  filters: new Filters([]),
  appliedFilters: [],
});

type FiltersPanelRootProps<T> = {
  children: ReactNode;
  filters?: Filters<T>;
};

export const FiltersPanelRoot = observer(function FiltersPanelRoot<T>({
  filters = new Filters([]),
  children,
}: FiltersPanelRootProps<T>) {
  const filterComponents = useMemo(
    () => [
      ...createPaginatedFilters(
        filters.visibleFilters.filter(({ isPaginated }) => isPaginated),
      ),
      ...createChoiceListFilter(
        filters.visibleFilters.filter(
          ({ dateRange, date, isMultiColumn, isPaginated }) =>
            !dateRange && !date && !isMultiColumn && !isPaginated,
        ),
      ),
      ...createDateRangeFilters(
        filters.visibleFilters.filter(
          ({ dateRange, date, isMultiColumn, isPaginated }) =>
            dateRange || (date && !isMultiColumn && !isPaginated),
        ),
      ),
      ...Object.values(MultiColumnFilterTypeEnum).flatMap((columnType) => {
        return createMultiColumnFilter(
          filters.visibleFilters.filter(
            ({ isMultiColumn, multiColumn, isPaginated }) =>
              isMultiColumn && multiColumn?.type === columnType && !isPaginated,
          ),
          columnType,
        );
      }),
    ],
    [filters.visibleFilters],
  );

  const appliedFilters: AppliedFilterInterface[] = map(
    map(filter(filters.visibleFilters, (f) => f.value.length !== 0)),
    (f) => {
      const key = f.column as unknown as string;
      const options = f.sectioned
        ? compact(flatten(map(f.options, (o) => o.options)))
        : f.options;

      return {
        key,
        label: `${f.tagTitle ?? f.title}: ${
          f.date || f.dateRange
            ? f.value.map((i) => formatDate(new Date(i))).join(" - ")
            : map(
                filter(options, (o) => f.value.includes(o.value)),
                "label",
              ).join(", ")
        }`,
        onRemove: () => {
          f.setValue([]);
        },
      };
    },
  );

  return (
    <FiltersPanelContext.Provider
      value={{ filters, appliedFilters, filterComponents }}
    >
      {children}
    </FiltersPanelContext.Provider>
  );
});

export function useFiltersPanel<T>(): ContextType<T> {
  const context = useContext(FiltersPanelContext) as ContextType<T>;
  if (!context) {
    throw new Error(
      'useFiltersPanel returned is "undefined". Seems you forgot to wrap the components in "<FiltersPanelRoot />"',
    );
  }
  return context;
}

type FiltersPanelProps<T = unknown> = {
  filters?: Filters<T>;
  searchPlaceholder?: string | undefined;
  onSearch: (term: string) => void;
  searchDefaultValue?: string;
};

export const FiltersPanel = observer(function FiltersPanel<T>({
  filters = new Filters([]),
  searchPlaceholder,
  onSearch,
  searchDefaultValue,
}: FiltersPanelProps<T>) {
  return (
    <FiltersPanelRoot filters={filters}>
      <Stack flex="1" spacing="2">
        <HStack justify="space-between" spacing="3">
          <FiltersPanelSearch
            onSearch={onSearch}
            defaultValue={searchDefaultValue}
            searchPlaceholder={searchPlaceholder}
          />
          {filters.visibleFilters.length > 0 && <FiltersPanelFilters />}
        </HStack>
        <FiltersPanelAppliedFilters />
      </Stack>
    </FiltersPanelRoot>
  );
});
