import { Badge, Tab } from "@chakra-ui/react";
import { Icon } from "@src/components/ui-kit/Icon";
import { TaskTabIdEnum } from "@src/constants/tasks";
import { UserType } from "@src/stores/models/Me";
import { useStore } from "@src/utils/hooks";
import { IconNames } from "@src/utils/types/iconNames";
import { observer, useLocalObservable } from "mobx-react-lite";
import { FunctionComponent, ReactNode } from "react";

export type TTaskTab = {
  id: TaskTabIdEnum;
  label: () => string;
  icon?: IconNames;
  component: ReactNode;
  colorScheme?: string;
  userTypesWithAccess: UserType[];
};

type TaskTabProps = {
  tab: TTaskTab;
};

export const TaskTab: FunctionComponent<TaskTabProps> = observer(
  function TaskTab({ tab }) {
    const { taskDetailModalStore: store } = useStore();

    const state = useLocalObservable(() => ({
      get badgeCount(): number | undefined {
        switch (tab.id) {
          case TaskTabIdEnum.Description:
            return undefined;
          case TaskTabIdEnum.Communication:
            return (
              (store.task.value?.commentCount ?? 0) +
              (store.task.value?.scopedCommentCount ?? 0)
            );
          case TaskTabIdEnum.Attachments:
            return store.task.value?.files.length ?? 0;
          case TaskTabIdEnum.LinkedTasks:
            return store.task.value?.linkedTasks?.length;
          default:
            return 0;
        }
      },
    }));

    return (
      <Tab>
        {tab.icon && (
          <Icon name={tab.icon} color="grey.500" mr="1" w="5" h="5" />
        )}
        {tab.label()}
        {!!state.badgeCount && (
          <Badge ml="1" colorScheme="grey">
            {state.badgeCount}
          </Badge>
        )}
      </Tab>
    );
  },
);
