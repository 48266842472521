import { Bank, WorkspaceSettingsQuery } from "@src/__generated__/urql-graphql";
import { action, makeObservable, observable } from "mobx";
import { AppStore } from "../AppStore";
import { BaseStore } from "../BaseStore";

export class BanksSettingsStore implements BaseStore {
  @observable.ref banks: Bank[] = [];

  constructor(public appStore: AppStore) {
    makeObservable(this);
  }

  @action.bound init(data: WorkspaceSettingsQuery) {
    this.banks = data.banksQuery ?? [];
  }

  @action.bound handleDisconnectBank() {}
}
