import { t } from "@lingui/macro";
import {
  CapacityAllocationRepetitionSettings,
  CapacityAllocationRepetitionTypeEnum,
  CapacityAllocationTypeEnum,
  CapacityAllocationUserItemDetailQuery,
  CreateCapacityAllocationInput,
  InputMaybe,
  ModalCapacityAllocationTaskOptionsQuery,
  RepetitionSettingsDaysEnum,
  Task,
  Team,
  TimeOffDurationTypeEnum,
  TimeOffType,
  UpdateCapacityAllocationForDayInput,
  UpdateCapacityAllocationInput,
  UpdateCapacityAllocationItemInput,
  User,
} from "@src/__generated__/urql-graphql";
import { PlanningDailyViewItemModel } from "@src/components/modules/resource-planning/timeline/models";
import { DEFAULT_TIME_DIFFERENCE } from "@src/constants/planning";
import {
  AllocationModalStore,
  InitialCapacityAllocationModalState,
} from "@src/stores/AllocationModalStore";
import { appStore } from "@src/stores/AppStore";
import { AffectAllocationOccurrences } from "@src/types/planning";
import {
  countMonthlyDateAppearanceOfInterval,
  daysCountOfIntervalWithoutWeekends,
  isAfterOrSame,
  isBeforeOrSame,
  weeksCountOfInterval,
  yearsCountOfInterval,
} from "@src/utils/dates";
import {
  gt,
  maxLength,
  minValue,
  mustBeAfter,
  mustBeBefore,
  required,
  time,
} from "@src/utils/forms/validators";
import { safeNumber } from "@src/utils/safeNumber";
import { timeToSeconds } from "@src/utils/time";
import { Option, Time } from "@src/utils/types";
import { TaskOption } from "@src/widgets/TaskSelect/TaskSelect";
import { addHours, addMinutes, eachDayOfInterval, isSameDay } from "date-fns";
import { FieldState, FormState } from "formstate";
import { omit, uniqueId } from "lodash";
import {
  ObservableMap,
  action,
  autorun,
  computed,
  makeObservable,
  observable,
} from "mobx";
import { match } from "ts-pattern";

export type PersonForm = FormState<{
  time_tracking_work_type_id: FieldState<string | undefined>;
  user_id: FieldState<string | undefined>;
}>;

type AllocationUserItemDetail = NonNullable<
  CapacityAllocationUserItemDetailQuery["capacityAllocationItemDetailQuery"]
>;
export type AssignmentType = "users" | "team" | "global";

export type AllocationFormData = {
  id: string;
  item: AllocationUserItemDetail;
  assignees: AllocationUserItemDetail["userItems"];
  tracked_for_item: PlanningDailyViewItemModel["tracked_for_item"];
};

type SerializeOptions =
  | {
      action: "update";
      affect: AffectAllocationOccurrences | "allOccurrencesFromToday";
    }
  | {
      action: "create";
      affect?: undefined;
    };

export class AllocationFormState {
  store: AllocationModalStore;

  requiredIfNotSpecificTime = (value: string | number) =>
    !this.specific_time.$ && required(value);
  requiredIfSpecificTime = (value: string | number | undefined) =>
    this.specific_time.$ ? required(value) : null;
  requiredIfNotAllDay = (value: string | number | undefined) =>
    (this.type.$ === CapacityAllocationTypeEnum.TimeOff ||
      this.type.$ === CapacityAllocationTypeEnum.HomeOffice) &&
    this.duration_type.$ !== TimeOffDurationTypeEnum.AllDay &&
    required(value);
  requiredIfTeamAssignment = (value: string[] | undefined) =>
    this.assignment_type.$ === "team" && required(value);
  requiredIfUsersAssignment = (value: string[] | string | undefined) =>
    this.assignment_type.$ === "users" && required(value);

  requiredIfCreatePositionsChecked = (value: string[] | string | undefined) =>
    this.assignment_type.$ !== "users" &&
    this.create_task_positions.$ &&
    required(value);
  requiredIfDeadlineTime = (value: Date | undefined) =>
    this.deadline_time.$ && required(value);
  validateRepetitionDays = (
    days: CapacityAllocationRepetitionSettings["days"],
  ): string | null => {
    const isWeeklyRepetition =
      this.repetition.value === CapacityAllocationRepetitionTypeEnum.Weekly;

    if (isWeeklyRepetition && !days?.length)
      return t`Please select days for repetition`;

    return null;
  };
  mustBeAfterFromDate = ($: Date) =>
    mustBeAfter(this.from_date, t`from date`)($);
  mustBeBeforeToDate = ($: Date) => mustBeBefore(this.to_date, t`to date`)($);
  mustBeAfterFromTime = ($: Time | undefined) => {
    if ($ === undefined || this.from_time.$ === undefined) return null;
    return $ < this.from_time.$ && t`Must be after from time`;
  };
  mustBeBeforeToTime = ($: Time | undefined) => {
    if ($ === undefined || this.to_time.$ === undefined) return null;
    return this.to_time.$ < $ && t`Must be before to time`;
  };
  userMustBeAssignedOnlyOnce = () => {
    const assignedUsers = Array.from(this.people.$).map(
      ([_, person]) => person.$.user_id.value,
    );
    return assignedUsers.length !== new Set(assignedUsers).size
      ? t`User must be assigned only once`
      : null;
  };

  @observable.ref selectedTask: TaskOption | undefined = undefined;
  @observable timeDifference: Time | undefined = DEFAULT_TIME_DIFFERENCE;
  type = new FieldState<CapacityAllocationTypeEnum>(
    CapacityAllocationTypeEnum.Task,
  );
  task_id = new FieldState<Task["id"]>("").validators(required);
  location = new FieldState<string>("").validators(maxLength(225));
  from_date = new FieldState<Date>(new Date()).validators(
    required,
    this.mustBeBeforeToDate,
  );
  to_date = new FieldState<Date>(new Date()).validators(
    required,
    this.mustBeAfterFromDate,
  );
  capacity_time_type = new FieldState<
    "total_hours" | "hours_per_day" | undefined
  >(undefined);
  specific_time = new FieldState<boolean>(false);
  from_time = new FieldState<Time | undefined>(undefined)
    .disableAutoValidation()
    .validators(
      this.mustBeBeforeToTime,
      this.requiredIfSpecificTime,
      this.requiredIfNotAllDay,
      time,
    );
  to_time = new FieldState<Time | undefined>(undefined)
    .disableAutoValidation()
    .validators(
      this.mustBeAfterFromTime,
      this.requiredIfSpecificTime,
      this.requiredIfNotAllDay,
      time,
    );
  total_time_per_day = new FieldState<number>(0);
  total_time = new FieldState<number>(0);
  deadline = new FieldState<Date | undefined>(undefined);
  deadline_time = new FieldState<Time | undefined>(undefined);
  people = new FormState<ObservableMap<string, PersonForm>>(observable.map([]));
  created_by_user_id = new FieldState<User["id"] | undefined>(
    undefined,
  ).validators(required);
  duration_type = new FieldState<TimeOffDurationTypeEnum>(
    TimeOffDurationTypeEnum.AllDay,
  );
  time_off_type_id = new FieldState<TimeOffType["id"]>("");
  attendees_ids = new FieldState<User["id"][]>([]);
  assignment_type = new FieldState<AssignmentType>("users");
  team_ids = new FieldState<Team["id"][] | undefined>(undefined);
  include_holidays = new FieldState<boolean>(false);
  repetition_every = new FieldState<
    CapacityAllocationRepetitionSettings["every"]
  >(1).validators(gt(0));
  repetition_end = new FieldState<CapacityAllocationRepetitionSettings["end"]>(
    new Date(),
  ).validators(required);
  repetition_days = new FieldState<
    CapacityAllocationRepetitionSettings["days"] | undefined
  >(undefined).validators(this.validateRepetitionDays);

  affect_occurrences = new FieldState<AffectAllocationOccurrences | undefined>(
    undefined,
  );

  repetition = new FieldState<CapacityAllocationRepetitionTypeEnum>(
    CapacityAllocationRepetitionTypeEnum.Once,
  );
  startType = new FieldState<TimeOffDurationTypeEnum>(
    TimeOffDurationTypeEnum.AllDay,
  );
  toType = new FieldState<TimeOffDurationTypeEnum>(
    TimeOffDurationTypeEnum.AllDay,
  );

  note = new FieldState<string>("").validators(maxLength(10000));

  create_task_positions = new FieldState<boolean>(false);

  repetition_settings = new FormState({
    every: this.repetition_every,
    end: this.repetition_end,
    days: this.repetition_days,
    include_holidays: this.include_holidays,
    startType: this.startType,
    toType: this.toType,
  });

  formByType: Record<CapacityAllocationTypeEnum, FormState<any>> = {
    [CapacityAllocationTypeEnum.Task]: new FormState({
      task_id: this.task_id.validators(required),
      people: this.people,
      note: this.note,
      specific_time: this.specific_time,
      total_time_per_day: this.total_time_per_day
        .disableAutoValidation()
        .validators(this.requiredIfNotSpecificTime, minValue(0)),
      total_time: this.total_time
        .disableAutoValidation()
        .validators(this.requiredIfNotSpecificTime, minValue(0)),
      from_date: this.from_date,
      to_date: this.to_date,
      from_time: this.from_time,
      to_time: this.to_time,
      deadline: this.deadline.validators(this.requiredIfDeadlineTime),
      deadline_time: this.deadline_time.validators(time),
      repetition: this.repetition,
      repetition_settings: this.repetition_settings,
      team_ids: this.team_ids.validators(this.requiredIfTeamAssignment),
      assignment_type: this.assignment_type,
      create_task_positions: this.create_task_positions,
      created_by_user_id: this.created_by_user_id,
    }),
    [CapacityAllocationTypeEnum.Meeting]: new FormState({
      task_id: this.task_id.validators(required),
      people: this.people,
      note: this.note,
      location: this.location,
      from_date: this.from_date,
      to_date: this.to_date,
      from_time: this.from_time,
      to_time: this.to_time,
      repetition: this.repetition,
      repetition_settings: this.repetition_settings,
      team_ids: this.team_ids.validators(this.requiredIfTeamAssignment),
      assignment_type: this.assignment_type,
      create_task_positions: this.create_task_positions,
      created_by_user_id: this.created_by_user_id,
    }),
    [CapacityAllocationTypeEnum.TimeOff]: new FormState({
      time_off_type_id: this.time_off_type_id.validators(required),
      from_date: this.from_date,
      to_date: this.to_date,
      from_time: this.from_time,
      to_time: this.to_time,
      repetition: this.repetition,
      repetition_settings: this.repetition_settings,
      duration_type: this.duration_type,
      attendees_id: this.attendees_ids.validators(
        this.requiredIfUsersAssignment,
      ),
      team_ids: this.team_ids.validators(this.requiredIfTeamAssignment),
      assignment_type: this.assignment_type,
      created_by_user_id: this.created_by_user_id,
    }),
    [CapacityAllocationTypeEnum.HomeOffice]: new FormState({
      from_date: this.from_date,
      to_date: this.to_date,
      from_time: this.from_time,
      to_time: this.to_time,
      repetition: this.repetition,
      repetition_settings: this.repetition_settings,
      duration_type: this.duration_type,
      attendees_id: this.attendees_ids.validators(
        this.requiredIfUsersAssignment,
      ),
      team_ids: this.team_ids.validators(this.requiredIfTeamAssignment),
      assignment_type: this.assignment_type,
      created_by_user_id: this.created_by_user_id,
    }),
    [CapacityAllocationTypeEnum.WeekendWork]: new FormState({
      from_date: this.from_date,
      to_date: this.to_date,
      from_time: this.from_time,
      to_time: this.to_time,
      repetition: this.repetition,
      repetition_settings: this.repetition_settings,
      duration_type: this.duration_type,
      attendees_id: this.attendees_ids.validators(
        this.requiredIfUsersAssignment,
      ),
      team_ids: this.team_ids.validators(this.requiredIfTeamAssignment),
      assignment_type: this.assignment_type,
      created_by_user_id: this.created_by_user_id,
    }),
  };

  constructor(store: AllocationModalStore) {
    makeObservable(this);
    this.store = store;
    this.addPerson();
    this.calculateHoursForType();
    this.setEODWhenLunchTimeIsSameAsAfternoon();
  }

  private getRepetitionSettings():
    | CapacityAllocationRepetitionSettings
    | undefined {
    if (this.repetition.value === "ONCE") return undefined;

    return {
      include_holidays: this.include_holidays.value,
      days: this.repetition_days.value,
      end: this.to_date.value,
      every: this.repetition_every.value,
      ...(this.duration_type.value === TimeOffDurationTypeEnum.AllDay && {
        startType: this.startType.value,
        toType: this.toType.value,
      }),
    };
  }

  @action.bound
  public async validate() {
    return await this.formByType[this.type.$].validate();
  }

  serialize = <T extends SerializeOptions>(
    options: T,
  ): T extends { action: "create"; affect: undefined }
    ? CreateCapacityAllocationInput
    : T extends { action: "update"; affect: "currentItem" }
      ? UpdateCapacityAllocationItemInput
      : T extends { action: "update"; affect: "allOccurrences" }
        ? UpdateCapacityAllocationInput
        : T extends { actions: "update"; affect: "allOccurrencesFromToday" }
          ? UpdateCapacityAllocationInput
          : T extends { action: "update"; affect: "thisDay" }
            ? UpdateCapacityAllocationForDayInput
            : never => {
    const attendees =
      this.type.$ === CapacityAllocationTypeEnum.TimeOff ||
      this.type.$ === CapacityAllocationTypeEnum.HomeOffice
        ? this.attendees_ids.$.map((id) => ({ user_id: id }))
        : Array.from(this.people.$).map(([_, attendee]) => ({
            time_tracking_work_type_id: attendee.$.time_tracking_work_type_id.$,
            user_id: attendee.$.user_id.$!,
          }));
    const capacityAllocation: CreateCapacityAllocationInput = {
      create_task_positions:
        this.assignment_type.$ === "users"
          ? true
          : this.create_task_positions.$,
      task_id: this.task_id.$,
      from_date: this.from_date.$!,
      to_date: this.to_date.$!,
      ...(this.type.$ === CapacityAllocationTypeEnum.Task
        ? {
            deadline: this.deadline.$,
            deadline_time: this.deadline_time.$,
          }
        : {}),
      ...(this.type.$ === CapacityAllocationTypeEnum.TimeOff ||
      this.type.$ === CapacityAllocationTypeEnum.HomeOffice
        ? {
            time_off_duration_type: this.duration_type.$,
            time_off_type_id: this.time_off_type_id.$,
          }
        : {}),
      ...(!!this.specific_time.$
        ? {
            from_time: this.from_time.$ ?? undefined,
            to_time: this.to_time.$ ?? undefined,
          }
        : {
            total_time: Math.round(this.total_time.$),
            total_time_per_day: Math.round(this.total_time_per_day.$),
            from_time: null,
            to_time: null,
          }),
      ...match(this.assignment_type.$)
        .with("users", () => ({
          attendees,
          global: false,
          team_ids: undefined,
        }))
        .with("team", () => ({
          attendees: this.create_task_positions ? attendees : undefined,
          team_ids: this.team_ids.$!,
          global: false,
        }))
        .with("global", () => ({
          attendees: this.create_task_positions ? attendees : undefined,
          team_ids: undefined,
          global: true,
        }))
        .exhaustive(),
      note: this.note.$,
      location: this.location.$,
      type: this.type.$ as unknown as CapacityAllocationTypeEnum,
      created_by_user_id: this.created_by_user_id.$,
      repetition: this.repetition.$,
      repetition_settings: this.getRepetitionSettings(),
    };

    // TS doesn't understand that we have correct return type, input is type checked above so we can ignore it safely
    // @ts-expect-error
    return match(options as SerializeOptions)
      .with({ action: "create" }, () => {
        if (
          this.type.$ === CapacityAllocationTypeEnum.TimeOff ||
          this.type.$ === CapacityAllocationTypeEnum.HomeOffice
        ) {
          return {
            ...capacityAllocation,
            ...(this.duration_type.$ === TimeOffDurationTypeEnum.AllDay
              ? {
                  from_time: appStore.workspaceStore.settings?.working_from,
                  to_time: appStore.workspaceStore.settings?.working_to,
                }
              : {}),
            ...(isSameDay(
              capacityAllocation.from_date,
              capacityAllocation.to_date,
            )
              ? {
                  repetition: CapacityAllocationRepetitionTypeEnum.Once,
                  repetition_settings: undefined,
                }
              : {}),
          };
        }

        return capacityAllocation;
      })
      .with({ action: "update", affect: "currentItem" }, () => ({
        ...omit(
          capacityAllocation,
          "total_time_per_day",
          "repetition",
          "from_date",
          "to_date",
          "deadline",
          "deadline_time",
          "repetition_settings",
          "attendees",
        ),
        id: this.store.modalState.additionalData?.formData?.id,
        date: capacityAllocation.from_date,
        user_id: Array.from(this.people.$.values())?.[0].$.user_id.$,
        time_tracking_work_type_id: Array.from(this.people.$.values())?.[0].$
          .time_tracking_work_type_id.$,
      }))
      .with({ action: "update", affect: "allOccurrences" }, () => ({
        ...capacityAllocation,
        id: this.store.modalState.additionalData?.formData?.id,
      }))
      .with({ action: "update", affect: "allOccurrencesFromToday" }, () => ({
        ...capacityAllocation,
        id: this.store.modalState.additionalData?.formData?.id,
        updating_item_id:
          this.store.modalState.additionalData?.formData?.item.id,
      }))
      .with({ action: "update", affect: "thisDay" }, () => ({
        ...omit(
          capacityAllocation,
          "from_date",
          "to_date",
          "repetition_settings",
          "repetition",
        ),
        capacity_allocation_id:
          this.store.modalState.additionalData?.formData?.item
            .capacityAllocation.id,
        date: this.from_date.$,
        updating_from_item_id:
          this.store.modalState.additionalData?.formData?.item.id,
      }))
      .run();
  };

  repeatOptions: Option<string, CapacityAllocationRepetitionTypeEnum>[] = [
    {
      label: t`Doesn't repeat`,
      value: CapacityAllocationRepetitionTypeEnum.Once,
    },
    { label: t`Daily`, value: CapacityAllocationRepetitionTypeEnum.Daily },
    { label: t`Weekly`, value: CapacityAllocationRepetitionTypeEnum.Weekly },
    { label: t`Monthly`, value: CapacityAllocationRepetitionTypeEnum.Monthly },
    { label: t`Yearly`, value: CapacityAllocationRepetitionTypeEnum.Yearly },
  ];

  durationTypeOptions: Option<string, TimeOffDurationTypeEnum>[] = [
    { label: t`All day`, value: TimeOffDurationTypeEnum.AllDay },
    { label: t`Half day(1/2)`, value: TimeOffDurationTypeEnum.FirstHalfOfDay },
    { label: t`Half day(2/2)`, value: TimeOffDurationTypeEnum.SecondHalfOfDay },
    { label: t`Custom`, value: TimeOffDurationTypeEnum.Custom },
  ];

  assignmentTypeOptions: Option<string, AssignmentType>[] = [
    { label: t`Users`, value: "users" },
    { label: t`Team`, value: "team" },
    { label: t`All`, value: "global" },
  ];

  startTypeOptions: Option<string, TimeOffDurationTypeEnum>[] = [
    { label: t`Morning`, value: TimeOffDurationTypeEnum.AllDay },
    { label: t`Afternoon`, value: TimeOffDurationTypeEnum.SecondHalfOfDay },
  ];

  @computed get toTypeOptions(): Option<string, TimeOffDurationTypeEnum>[] {
    const ltOption = {
      label: t`Lunchtime`,
      value: TimeOffDurationTypeEnum.FirstHalfOfDay,
    };
    let options = [
      { label: t`End of day`, value: TimeOffDurationTypeEnum.AllDay },
    ];

    if (
      this.from_date.value.toDateString() !==
        this.to_date.value.toDateString() ||
      this.startType.value !== TimeOffDurationTypeEnum.SecondHalfOfDay
    ) {
      options = [ltOption, ...options];
    }

    return options;
  }

  @computed get selectedRepeatOption(): Option<
    string,
    CapacityAllocationRepetitionTypeEnum
  > {
    return this.repeatOptions.find(
      (option) => option.value === this.repetition.value,
    )!;
  }

  getCurrentTimeDifference() {
    if (this.from_time.value === undefined || this.to_time.value === undefined)
      return;

    const [fromHours, fromMinutes] = this.from_time.value?.split(":");
    const [toHours, toMinutes] = this.to_time.value?.split(":");

    this.timeDifference = `${Number(toHours) - Number(fromHours)}:${
      Number(toMinutes) - Number(fromMinutes)
    }` as Time;
  }

  shiftToTimeByCurrentDifference() {
    if (this.from_time.value === undefined) return;
    const [hours, minutes] = this.from_time.value.split(":");
    const [hoursDifference, minutesDifference] = (
      this.timeDifference ?? DEFAULT_TIME_DIFFERENCE
    ).split(":");
    const fromTimeDate = new Date();

    const newHours = addHours(
      fromTimeDate.setHours(Number(hours)),
      Number(hoursDifference),
    ).getHours();
    const newHoursWithPrefix = newHours < 10 ? `0${newHours}` : newHours;
    const newMinutes = addMinutes(
      fromTimeDate.setMinutes(Number(minutes)),
      Number(minutesDifference),
    ).getMinutes();
    const newMinutesWithPrefix =
      newMinutes < 10 ? `0${newMinutes}` : newMinutes;

    this.to_time.onChange(
      `${newHoursWithPrefix}:${newMinutesWithPrefix}` as Time,
    );
    this.from_time.validate();
    this.to_time.validate();
  }

  setEODWhenLunchTimeIsSameAsAfternoon() {
    autorun(() => {
      if (
        this.from_date.value.toDateString() ===
          this.to_date.value.toDateString() &&
        this.startType.value === TimeOffDurationTypeEnum.SecondHalfOfDay &&
        this.toType.value === TimeOffDurationTypeEnum.FirstHalfOfDay
      ) {
        this.toType.onChange(TimeOffDurationTypeEnum.AllDay);
      }
    });
  }

  @computed get timePerDayFromSpecificTime(): number {
    if (!this.to_time.value || !this.from_time.value) return 0;
    return (
      (timeToSeconds(this.to_time.value) ?? 0) -
      (timeToSeconds(this.from_time.value) ?? 0)
    );
  }

  @computed get totalTimeFromSpecificTime(): number {
    return (
      this.timePerDayFromSpecificTime *
      this.occurrenceCount *
      this.assigneeCount
    );
  }

  @computed get assigneeCount() {
    switch (this.assignment_type.$) {
      case "global":
        return this.store.plannableUsers.length;
      case "team":
        if (!this.team_ids.$) {
          return 1;
        }
        return this.team_ids.$.reduce((prev, curr) => {
          return (
            prev +
            this.store.plannableUsers.filter(({ team_id }) => team_id === curr)
              .length
          );
        }, 1);
      case "users":
        return this.people.$.size >= 1 ? this.people.$.size : 1;
    }
  }

  @computed get occurrenceCount() {
    return this.calculateOccurrenceForType(
      this.from_date.value,
      this.to_date.value,
      this.repetition.value,
      this.repetition_every.value,
      this.repetition_days.value ?? [],
      this.include_holidays.value,
    );
  }

  calculateOccurrenceForType(
    from: Date,
    to: Date,
    repetitionType: CapacityAllocationRepetitionTypeEnum,
    repetitionValue = 1,
    repetitionDays: Array<InputMaybe<RepetitionSettingsDaysEnum>> = [],
    includeHolidays = false,
  ): number {
    if (repetitionType === CapacityAllocationRepetitionTypeEnum.Once) return 1;
    if (isAfterOrSame(from, to)) return 1;
    if (isBeforeOrSame(to, from)) return 1;

    const diffDaysWithoutWeekends = daysCountOfIntervalWithoutWeekends(
      from,
      to,
    );
    const diffDaysWithWeekends = eachDayOfInterval({
      start: from,
      end: to,
    }).length;
    const diffDays = includeHolidays
      ? diffDaysWithWeekends
      : diffDaysWithoutWeekends;
    const applicableDays = Math.floor(diffDays / repetitionValue);

    const diffWeeks = weeksCountOfInterval(from, to);
    const applicableWeeks = Math.floor(diffWeeks / repetitionValue);

    const diffMonths = countMonthlyDateAppearanceOfInterval({
      startDate: from,
      endDate: to,
      targetDate: from,
    });
    const applicableMonths = Math.floor(diffMonths / repetitionValue);

    const diffYears = yearsCountOfInterval(from, to);
    const applicableYears = Math.floor(diffYears / repetitionValue);

    return {
      [CapacityAllocationRepetitionTypeEnum.Daily]: applicableDays,
      [CapacityAllocationRepetitionTypeEnum.Weekly]:
        applicableWeeks * repetitionDays.length,
      [CapacityAllocationRepetitionTypeEnum.Monthly]: applicableMonths,
      [CapacityAllocationRepetitionTypeEnum.Yearly]: applicableYears,
      // This option isn't even accessible but without it TS said it can return undefined
      [CapacityAllocationRepetitionTypeEnum.Custom]: 1,
    }[repetitionType];
  }

  calculateHoursForType() {
    autorun(() => {
      if (
        CapacityAllocationRepetitionTypeEnum.Once === this.repetition.value &&
        this.assigneeCount === 1
      ) {
        // Set total_time_per_day to total time so BE will not overwrite total_time while editing allocation allocation with one occurrence
        this.total_time_per_day.onChange(this.total_time.value);
        return;
      }

      switch (this.capacity_time_type.$) {
        case "total_hours":
          this.total_time_per_day.onChange(
            safeNumber(
              Number(this.total_time.value) /
                this.occurrenceCount /
                this.assigneeCount,
            ),
          );
          break;
        default:
          this.total_time.onChange(
            safeNumber(
              Number(this.total_time_per_day.value) *
                this.occurrenceCount *
                this.assigneeCount,
            ),
          );
          break;
      }
    });
  }

  @action.bound addPerson(
    time_tracking_work_type_id?: string,
    user_id?: string,
  ): void {
    this.people.$.set(
      uniqueId("position"),
      new FormState({
        time_tracking_work_type_id: new FieldState<string | undefined>(
          time_tracking_work_type_id,
        ).validators(
          this.requiredIfUsersAssignment,
          this.requiredIfCreatePositionsChecked,
        ),
        user_id: new FieldState<string | undefined>(user_id).validators(
          this.requiredIfUsersAssignment,
          this.requiredIfCreatePositionsChecked,
          this.userMustBeAssignedOnlyOnce,
        ),
      }),
    );
  }

  @action.bound removePerson(id: string): void {
    this.people.$.delete(id);
    if (this.people.$.size === 0) {
      this.addPerson();
    }
  }

  @action.bound autoCompleteUserPositionFromTask(
    task: ModalCapacityAllocationTaskOptionsQuery["task"],
  ): void {
    if (this.assignment_type.value === "users") {
      this.people.$.forEach((person) => {
        const userInTask = this.userInTask(person.$.user_id.$, task);
        if (!userInTask) {
          person.$.time_tracking_work_type_id.reset();
          return;
        }
        person.$.time_tracking_work_type_id.onChange(
          userInTask.timeTrackingWorkType.id,
        );
        person.$.user_id.onChange(userInTask.user?.id);
      });
    } else {
      if (!this.create_task_positions.value) return;
      this.people.$.forEach((person) => {
        const workTypeId = this.userInTask(person.$.user_id.$, task)
          ?.timeTrackingWorkType.id;
        if (!workTypeId) return;
        person.$.time_tracking_work_type_id.onChange(workTypeId);
      });
    }
  }

  @action.bound userInTask(
    userId: string | undefined,
    task: ModalCapacityAllocationTaskOptionsQuery["task"] | undefined,
  ) {
    return task?.positions?.find(({ user }) => user?.id === userId);
  }

  @action.bound reset({
    preserveAutoFilledState,
  }: {
    preserveAutoFilledState: boolean;
  }): void {
    const preservedState: InitialCapacityAllocationModalState = {
      userId: this.attendees_ids.value[0],
      date: this.from_date.value,
    };
    this.type.reset();
    this.affect_occurrences.reset();
    this.formByType.MEETING.reset();
    this.formByType.TASK.reset();
    this.formByType.TIME_OFF.reset();
    this.attendees_ids.reset([]);
    this.people.$.clear();
    this.selectedTask = undefined;
    this.addPerson();
    if (preserveAutoFilledState) {
      this.autoFillOnOpen(preservedState);
    }
  }

  @action fillUsersFromTask() {
    switch (this.assignment_type.value) {
      case "users":
        return;
      case "team":
        this.people.$.clear();
        const users = this.store.plannableUsers.filter(({ team_id }) => {
          if (!team_id) return false;
          return this.team_ids.value?.includes(team_id);
        });
        users.forEach((user) => {
          this.addPerson(undefined, user.id);
        });
        if (this.selectedTask?.projectId && this.task_id.value) {
          this.store.fetchPersonOptions({
            projectId: this.selectedTask.projectId,
            taskId: this.task_id.value,
            autoCompleteUserPositionFromTask: true,
          });
        }
        return;
      case "global":
        this.people.$.clear();
        this.store.plannableUsers.forEach((user) => {
          this.addPerson(undefined, user.id);
        });
        if (this.selectedTask?.projectId && this.task_id.value) {
          this.store.fetchPersonOptions({
            projectId: this.selectedTask.projectId,
            taskId: this.task_id.value,
            autoCompleteUserPositionFromTask: true,
          });
        }
        return;
    }
  }

  autoFillOnOpen = async ({
    userId,
    date,
    task,
  }: InitialCapacityAllocationModalState) => {
    this.from_date.onChange(date);
    this.to_date.onChange(date);
    this.attendees_ids.onChange([userId]);
    this.people.$.clear();
    this.addPerson(undefined, userId);
    this.created_by_user_id.onChange(appStore.authStore.user?.id);
    if (!task) return;
    this.task_id.onChange(task.id);
    this.selectedTask = {
      value: task.id,
      label: task.name,
      projectId: task.ourWorkBudgetItem?.project.id,
      budgetItemId: task.ourWorkBudgetItem?.id,
      billable: task.billable,
      timeTrackingSettings: task.ourWorkBudgetItem?.timeTrackingSettings,
    };
    if (task.ourWorkBudgetItem) {
      await this.store.fetchPersonOptions({
        projectId: task.ourWorkBudgetItem?.project.id,
        taskId: this.task_id.value,
        autoCompleteUserPositionFromTask: false,
      });
    }
    this.people.$.clear();
    this.addPerson(undefined, userId);
  };

  @action.bound convertAllocationItemToFromState = async (
    formData: Omit<AllocationFormData, "id">,
    affectedOccurrences: AffectAllocationOccurrences,
  ) => {
    const { item: allocationItem, assignees } = formData;
    if (!allocationItem) return;

    if (allocationItem.global) {
      this.assignment_type.onChange("global");
    } else if (
      allocationItem.capacityAllocation &&
      !!allocationItem.teams?.length &&
      affectedOccurrences !== "currentItem"
    ) {
      this.assignment_type.onChange("team");
      this.team_ids.onChange(allocationItem.teams?.map(({ team }) => team.id));
    } else {
      this.assignment_type.onChange("users");
    }

    this.type.onChange(allocationItem.type);

    if (allocationItem.timeOffType?.id) {
      this.time_off_type_id.onChange(allocationItem.timeOffType.id);
      this.duration_type.onChange(allocationItem.time_off_duration_type!);
      this.attendees_ids.onChange(assignees.map(({ user }) => user.id));
    }

    if (allocationItem.type === CapacityAllocationTypeEnum.HomeOffice) {
      this.duration_type.onChange(allocationItem.time_off_duration_type!);
      this.attendees_ids.onChange(assignees.map(({ user }) => user.id));
    }

    if (allocationItem.capacityAllocation?.task) {
      this.create_task_positions.onChange(
        allocationItem.capacityAllocation.create_task_positions,
      );
      this.task_id.onChange(allocationItem.capacityAllocation.task.id);
      this.selectedTask = {
        value: allocationItem.capacityAllocation.task.id,
        label: allocationItem.capacityAllocation.task.name,
        projectId:
          allocationItem.capacityAllocation.task.ourWorkBudgetItem?.project.id,
        budgetItemId:
          allocationItem.capacityAllocation.task.ourWorkBudgetItem?.id,
        billable: allocationItem.capacityAllocation.task.billable,
        timeTrackingSettings: undefined,
      };

      await this.store.fetchPersonOptions({
        projectId:
          allocationItem.capacityAllocation.task.ourWorkBudgetItem?.project.id,
        taskId: this.task_id.value,
        autoCompleteUserPositionFromTask: false,
      });
    }

    if (allocationItem.location) {
      this.location.onChange(allocationItem.location);
    }
    this.deadline.onChange(
      allocationItem.capacityAllocation?.deadline ?? undefined,
    );
    this.deadline_time.onChange(
      allocationItem.capacityAllocation?.deadline_time ?? undefined,
    );

    if (
      affectedOccurrences === "allOccurrences" ||
      affectedOccurrences === "thisDay"
    ) {
      this.created_by_user_id.onChange(
        allocationItem.capacityAllocation?.createdBy.id,
      );
      if (allocationItem.capacityAllocation.from_time) {
        this.from_time.onChange(allocationItem.capacityAllocation.from_time);
      }
      if (allocationItem.capacityAllocation.to_time) {
        this.to_time.onChange(allocationItem.capacityAllocation.to_time);
      }
      this.specific_time.onChange(
        !!allocationItem.capacityAllocation.from_time,
      );
      if (allocationItem.capacityAllocation.note) {
        this.note.onChange(allocationItem.capacityAllocation.note);
      }
      if (allocationItem.capacityAllocation.location) {
        this.location.onChange(allocationItem.capacityAllocation.location);
      }

      if (
        allocationItem.capacityAllocation.total_time &&
        allocationItem.capacityAllocation.total_time_per_day &&
        !allocationItem.from_time
      ) {
        this.total_time.onChange(allocationItem.capacityAllocation.total_time);
        this.total_time_per_day.onChange(
          allocationItem.capacityAllocation.total_time_per_day,
        );
      }

      if (affectedOccurrences === "thisDay") {
        this.repetition.onChange(CapacityAllocationRepetitionTypeEnum.Once);
        this.from_date.onChange(allocationItem.date);
        this.to_date.onChange(allocationItem.date);
      } else {
        this.from_date.onChange(allocationItem.capacityAllocation?.from_date);
        this.to_date.onChange(allocationItem.capacityAllocation?.to_date);
        this.repetition.onChange(allocationItem.capacityAllocation.repetition);
        if (allocationItem.capacityAllocation.repetition_settings) {
          this.include_holidays.onChange(
            allocationItem.capacityAllocation.repetition_settings
              .include_holidays ?? false,
          );
          this.repetition_days.onChange(
            allocationItem.capacityAllocation.repetition_settings.days,
          );
          this.repetition_every.onChange(
            allocationItem.capacityAllocation.repetition_settings.every,
          );
          this.startType.onChange(
            allocationItem.capacityAllocation.repetition_settings.startType ??
              TimeOffDurationTypeEnum.AllDay,
          );
          this.toType.onChange(
            allocationItem.capacityAllocation.repetition_settings.toType ??
              TimeOffDurationTypeEnum.AllDay,
          );
          // TODO: end should be Date type on api
          this.repetition_end.onChange(
            new Date(allocationItem.capacityAllocation.repetition_settings.end),
          );
        }
      }

      this.people.$.clear();
      assignees?.map(({ time_tracking_work_type_id, user }) => {
        this.addPerson(time_tracking_work_type_id ?? undefined, user.id);
      });
    }

    if (affectedOccurrences === "currentItem") {
      this.created_by_user_id.onChange(allocationItem?.createdBy?.id);
      if (allocationItem.from_time) {
        this.from_time.onChange(allocationItem.from_time);
      }
      if (allocationItem.to_time) {
        this.to_time.onChange(allocationItem.to_time);
      }
      this.from_date.onChange(allocationItem.date);
      this.to_date.onChange(allocationItem.date);
      this.specific_time.onChange(!!allocationItem.from_time);
      if (allocationItem.note) {
        this.note.onChange(allocationItem.note);
      }

      if (allocationItem.total_time) {
        this.total_time.onChange(allocationItem.total_time);
        this.total_time_per_day.onChange(allocationItem.total_time);
      }
      this.people.$.clear();
      const matchingUserItem = allocationItem.userItems.find(
        ({ id }) => id === allocationItem.id,
      );
      this.addPerson(
        matchingUserItem?.time_tracking_work_type_id ?? undefined,
        matchingUserItem?.user.id,
      );
    }
  };
}
