import { Button, Center, Flex, Stack, TabPanel, Text } from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import { Task, TaskQuery } from "@src/__generated__/urql-graphql";
import { Icon } from "@src/components/ui-kit/Icon";
import { TaskItemSubtask } from "@src/components/ui-kit/TaskItem/TaskItemSubtask";
import { useStore } from "@src/utils/hooks";
import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

export type Subtasks = NonNullable<TaskQuery["task"]["subtasks"]>;

export const SubtasksTab: FunctionComponent = observer(function SubtasksTab() {
  const { taskDetailModalStore: store, taskFormModalStore } = useStore();

  const onTaskClick = (id: Task["id"]) => {
    window.open(`${location.origin}/notifications?taskId=${id}`, "_blank");
  };

  return (
    <TabPanel px="8">
      <Stack spacing="0">
        {store.task.value?.subtasks?.length ? (
          store.task.value?.subtasks?.map((subtask) => (
            <TaskItemSubtask
              key={subtask.id}
              task={subtask}
              onClick={(task) => {
                onTaskClick(task.id);
              }}
            />
          ))
        ) : (
          <Center mt="2">
            <Text>
              <Trans>No subtasks</Trans>
            </Text>
          </Center>
        )}
      </Stack>
      <Flex justify="end" w="full" mt="2">
        <Button
          leftIcon={<Icon name="plus" />}
          onClick={() => {
            if (!store.task.value) return;

            taskFormModalStore.modalState.onOpen({
              billable: store.task.value.billable,
              budgetItemId: store.task.value.ourWorkBudgetItem?.id,
              isTemplate: store.task.value.is_template,
              parentTask: store.task.value
                ? {
                    id: store.task.value.id,
                    name: store.task.value.name,
                    status: store.task.value.status,
                    ourWorkBudgetItem: store.task.value.ourWorkBudgetItem,
                  }
                : undefined,
              projectId: store.task.value.ourWorkBudgetItem?.project.id,
              templateGroupId:
                store.task.value.task_template_group_id ?? undefined,
              userId: store.task.value.createdBy?.id,
              onSubmit: (task) => {
                store.drawerState.onOpen({
                  ...store.drawerState.additionalData,
                  id: task.id,
                });
              },
            });
          }}
        >
          <Trans>Subtask</Trans>
        </Button>
      </Flex>
    </TabPanel>
  );
});
