import {
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
} from "@chakra-ui/react";
import {
  TaskQuery,
  TaskStatus as TaskStatusType,
  useTaskStatusesQuery,
  useUpdateTaskStatusMutation,
} from "@src/__generated__/urql-graphql";
import { TaskStatusBadge } from "@src/components/ui-kit";
import { Icon } from "@src/components/ui-kit/Icon";
import { can } from "@src/utils/components/permissions";
import { useStore } from "@src/utils/hooks";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import { FunctionComponent, useEffect } from "react";

type TaskStatusProps = {
  task: Pick<TaskQuery["task"], "id" | "status">;
  onChange?: (status: TaskStatusType) => void;
  isChevronAlwaysVisible?: boolean;
};

export const TaskStatus: FunctionComponent<TaskStatusProps> = observer(
  function TaskStatus({ task, isChevronAlwaysVisible = false, onChange }) {
    const { taskDetailModalStore: store, tasksListingStore } = useStore();
    const [{ fetching: loadingUpdateStatus }, updateStatus] =
      useUpdateTaskStatusMutation();

    const [{ data }] = useTaskStatusesQuery({
      pause: store.taskStatuses.value.length > 0,
    });

    useEffect(() => {
      if (data) {
        store.taskStatuses.set(data.taskStatuses);
      }
    }, [data]);

    const handleChoiceListChange = (id: string) => {
      if (!task?.id) return;

      updateStatus({ task_id: task.id, status_id: id }).then(({ data }) => {
        if (!data?.updateTaskStatus) return;
        store.task.value?.setStatus(data.updateTaskStatus.status);
        tasksListingStore.updateTask(data.updateTaskStatus);
        onChange?.(data.updateTaskStatus.status);
      });
    };

    const canEditStatus = computed(() => {
      return (
        can("task_update_status_all") ||
        can({
          do: "task_update_status_own",
          eval: (user) => store.task.value?.createdBy?.id === user.id,
        })
      );
    }).get();

    const hasOtherTaskStatusOptions = store.taskStatuses.value?.length > 0;

    if (!hasOtherTaskStatusOptions) {
      return (
        <TaskStatusBadge
          name={task.status.name}
          foreground_color={task.status.foreground_color}
          background_color={task.status.background_color}
        />
      );
    }

    return (
      <Menu>
        <MenuButton data-group disabled={!canEditStatus}>
          <HStack>
            <div>
              {loadingUpdateStatus && <Spinner color="teal.500" size="sm" />}
              {!loadingUpdateStatus && (
                <TaskStatusBadge
                  name={task.status.name}
                  foreground_color={task.status.foreground_color}
                  background_color={task.status.background_color}
                />
              )}
            </div>
            {canEditStatus && (
              <Icon
                name="chevron-down"
                w="4"
                h="4"
                color="grey.500"
                {...(!isChevronAlwaysVisible && {
                  display: "none",
                  _groupHover: { display: "inline-flex" },
                })}
              />
            )}
          </HStack>
        </MenuButton>
        <MenuList>
          {store.taskStatuses.value.map((status) => (
            <MenuItem
              key={status.id}
              color={status.foreground_color}
              fontWeight="medium"
              onClick={() => handleChoiceListChange(status.id)}
            >
              {status.name}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    );
  },
);
