import { BudgetGroupFragment } from "@src/__generated__/graphql";
import { budgetovacApi } from "@src/services/budgetovac";
import { toApiDate } from "@src/utils/dates";
import { BooleanState } from "@src/utils/mobx/states/BooleanState";
import { FieldState } from "formstate";
import { action, computed, makeObservable, observable } from "mobx";
import { now } from "mobx-utils";

type Budget = NonNullable<NonNullable<BudgetGroupFragment["budgets"]>[0]> & {
  budget_name?: string | null;
  __subRows?: BudgetModel[];
};
export class BudgetModel implements Omit<Budget, "description"> {
  readonly descriptionEditDataComponentName: string;

  id!: Budget["id"];
  status!: Budget["status"];
  created_at!: Budget["created_at"];
  updated_at!: Budget["updated_at"];
  project: Budget["project"];
  project_id: Budget["project_id"];
  temporary_project_id: Budget["temporary_project_id"];
  temporaryProject: Budget["temporaryProject"];
  brand: Budget["brand"];
  temporaryBrand: Budget["temporaryBrand"];
  account_manager: Budget["account_manager"];
  version: Budget["version"];
  price: Budget["price"];
  currency: Budget["currency"];
  budget_name?: Budget["budget_name"];
  description: FieldState<string>;

  isArchived;
  isDescriptionEditEnabled = new BooleanState(false);
  @observable __subRows?: Budget["__subRows"];
  @observable.ref is_active: Budget["is_active"];
  @observable.ref assignment_date: Budget["assignment_date"];
  @observable.ref internally_approved: Budget["internally_approved"];
  @observable.ref client_approved: Budget["client_approved"];

  isArchiving = new BooleanState(false);
  isDeleting = new BooleanState(false);
  isDownloading = new BooleanState(false);
  isAssigning = new BooleanState(false);
  isDescriptionUpdating = new BooleanState(false);
  isUpdatingAssignmentDate = new BooleanState(false);
  isApproving = {
    internal: new BooleanState(false),
    client: new BooleanState(false),
  };

  constructor(budget: Budget) {
    makeObservable(this);
    Object.assign(this, budget);
    this.descriptionEditDataComponentName = `budget-description-edit-for-${budget.id}`;
    this.description = new FieldState(budget.description ?? "");
    this.isArchived = new BooleanState(
      budget.temporaryProject?.is_archived ?? false,
    );
  }

  @computed get isActionsLoading() {
    return (
      this.isArchiving.value ||
      this.isDeleting.value ||
      this.isDownloading.value
    );
  }

  @computed get isArchiveDisabled() {
    return Boolean(this.project) && !this.temporary_project_id;
  }

  @action.bound async handleArchive() {
    if (!this.temporary_project_id) return;
    this.isArchiving.on();

    try {
      await budgetovacApi.updateArchivedStatus(
        this.temporary_project_id,
        !this.isArchived,
      );
      this.isArchived.toggle();
    } catch (e) {
      console.error("BudgetovacAPI: ", e);
    }

    this.isArchiving.off();
  }

  @computed get isDeleteDisabled() {
    return (
      Boolean(this.project) &&
      Boolean(this.project_id) &&
      Boolean(this.is_active)
    );
  }

  @action.bound async handleDelete() {
    if (this.isDeleteDisabled) return;
    this.isDeleting.on();

    try {
      await budgetovacApi.deleteBudget(this.id);
    } catch (e) {
      console.error("BudgetovacAPI: ", e);
    }

    this.isDeleting.off();
  }

  @action.bound async handleXlsxDownload(language: string) {
    this.isDownloading.on();

    try {
      const { data } = await budgetovacApi.downloadBudget(this.id, language);
      window.open(data.download_link, "_blank");
    } catch (e) {
      console.error("BudgetovacAPI: ", e);
    }

    this.isDownloading.off();
  }

  @action.bound async handleApproveInternal(isChecked: boolean) {
    this.isApproving.internal.on();

    try {
      await budgetovacApi.approveInternally(this.id, isChecked);
      this.internally_approved = isChecked;
    } catch (e) {
      console.error("BudgetovacAPI: ", e);
    }

    this.isApproving.internal.off();
  }

  @action.bound async handleApproveClient(isChecked: boolean) {
    this.isApproving.client.on();

    try {
      await budgetovacApi.approveClient(this.id, isChecked);
      this.client_approved = isChecked;
    } catch (e) {
      console.error("BudgetovacAPI: ", e);
    }

    this.isApproving.client.off();
  }

  @action.bound async handleAssign() {
    this.isAssigning.on();

    try {
      await budgetovacApi.assign(this.id);
      if (this.project_id) {
        await budgetovacApi.assignedBudgetGroupsByProject(this.project_id);
      }
      this.is_active = true;
      this.assignment_date = toApiDate(now());
    } catch (e) {
      console.error(e);
    }

    this.isAssigning.off();
  }

  @action.bound async handleUnassign() {
    this.isAssigning.on();

    try {
      await budgetovacApi.unassign(this.id);
      if (this.project_id) {
        await budgetovacApi.assignedBudgetGroupsByProject(this.project_id);
      }
      this.is_active = false;
    } catch (e) {
      console.error(e);
    }

    this.isAssigning.off();
  }

  @action.bound async handleUpdateDescription(description: string) {
    this.isDescriptionUpdating.on();

    try {
      await budgetovacApi.updateDescription(this.id, description);
      this.description = new FieldState(description);
    } catch (e) {
      console.error(e);
    }

    this.isDescriptionUpdating.off();
  }

  @action.bound async handleUpdateAssignmentDate(date: Date) {
    this.isUpdatingAssignmentDate.on();

    try {
      const newDate = toApiDate(date);
      await budgetovacApi.updateAssignmentDate(this.id, newDate);
      this.assignment_date = newDate;
    } catch (e) {
      console.error(e);
    }

    this.isUpdatingAssignmentDate.off();
  }
}
