import { t } from "@lingui/macro";
import { captureEvent } from "@sentry/nextjs";
import {
  LastSeenCommentDocument,
  LastSeenCommentMutation,
  LastSeenCommentMutationVariables,
  TaskCommentsDocument,
  TaskCommentsQuery,
  TaskCommentsQueryVariables,
  UserScopeEnum,
} from "@src/__generated__/graphql";
import { FetchHelper } from "@src/helpers/apollo/fetch";
import { MutationHelper } from "@src/helpers/apollo/mutation";
import { AppStore } from "@src/stores/AppStore";
import { PaginationState } from "@src/utils/mobx/states/PaginationState";
import { action, computed, makeObservable, observable } from "mobx";
import { CommentModel } from "../../models/CommentModel";
import { CommunicationTab } from "./CommunicationTab";
import { TTaskTab } from "./TaskTab";

type TabInfo = Pick<
  TTaskTab,
  "id" | "label" | "component" | "userTypesWithAccess"
> & {
  userScope: UserScopeEnum | undefined;
};
export class CommunicationTabParentStore {
  private readonly InternalTabInfo: TabInfo = {
    id: 0,
    label: () => t`Internal`,
    component: <CommunicationTab userType="internal" />,
    userTypesWithAccess: ["internal"],
    userScope: undefined,
  };
  private readonly ClientTabInfo: TabInfo = {
    id: 1,
    label: () => t`Client`,
    component: <CommunicationTab userType="client" />,
    userTypesWithAccess: ["internal", "client"],
    userScope: UserScopeEnum.Client,
  };
  private readonly PartnerTabInfo: TabInfo = {
    id: 2,
    label: () => t`Partner`,
    component: <CommunicationTab userType="partner" />,
    userTypesWithAccess: ["internal", "partner"],
    userScope: UserScopeEnum.Partner,
  };

  pagination = new PaginationState("comments-pagination", {
    page: 0,
    onChangePagination: (updatedPagination) => {
      this.fetchTaskComments(updatedPagination);
    },
  });
  commentsFetcher = new FetchHelper<
    TaskCommentsQuery,
    TaskCommentsQueryVariables
  >(TaskCommentsDocument);
  lastSeenCommentMutator = new MutationHelper<
    LastSeenCommentMutation,
    LastSeenCommentMutationVariables
  >(LastSeenCommentDocument);

  @observable.ref selectedTab = 0;
  @observable comments: CommentModel[] = [];

  constructor(private appStore: AppStore) {
    makeObservable(this);
  }

  @computed get availableTabs(): TabInfo[] {
    if (!this.appStore.authStore.user?.type) return [];
    return {
      internal: [this.InternalTabInfo, this.ClientTabInfo, this.PartnerTabInfo],
      client: [this.ClientTabInfo],
      partner: [this.PartnerTabInfo],
    }[this.appStore.authStore.user?.type];
  }

  @computed get selectedTabInfo(): TabInfo {
    return this.availableTabs[this.selectedTab];
  }

  @action.bound reset() {
    this.comments = [];
    this.appStore.taskDetailModalStore.seenLastComment.off();
    this.pagination.resetPage();
  }

  @action.bound setSelectedTab(tab: number) {
    this.selectedTab = tab;
    this.reset();
  }

  @computed get lastCommentId() {
    if (!this.comments.length) return undefined;
    return this.comments[this.comments.length - 1].id;
  }

  @action.bound async fetchTaskComments(pagination: PaginationState) {
    if (!this.appStore.taskDetailModalStore.taskId.value) return;

    const [data, error] = await this.commentsFetcher.fetch({
      id: this.appStore.taskDetailModalStore.taskId.value,
      scope: this.selectedTabInfo.userScope ?? UserScopeEnum.Internal,
      page: 1,
      first: pagination.asParams.first,
      lastCommentId: this.lastCommentId,
    });

    if (error || !data.taskComments) return;

    for (const comment of data.taskComments.data) {
      if (this.comments.find(({ id }) => comment.id === id)) continue;
      this.comments.push(new CommentModel(comment));
    }

    this.pagination.setFromPaginatorInfo(data.taskComments.paginatorInfo);
  }

  @action.bound async handleSeenLastComment() {
    this.appStore.taskDetailModalStore.seenLastComment.on();
    const [, error] = await this.lastSeenCommentMutator.mutate({
      comment_id: this.comments[0].id,
    });

    if (!error) return;

    this.appStore.UIStore.toast({
      status: "error",
      title: error instanceof Error ? error.message : error[0]?.message,
    });
  }

  @action.bound handleAddComment(comment: CommentModel) {
    this.comments.unshift(comment);
  }

  @action.bound handleUpdateComment(idToUpdate: string, comment: CommentModel) {
    const matchingCommentIndex = this.comments.findIndex(
      (comment) => comment.id === idToUpdate,
    );

    if (matchingCommentIndex === -1) {
      this.appStore.UIStore.toast({
        status: "error",
      });
      captureEvent({
        message: "FE: Unable to find matching comment ID.",
      });
      return;
    }

    this.comments.splice(matchingCommentIndex, 1, comment);
  }

  @action.bound handleDeleteComment(id: string) {
    const matchingCommentIndex = this.comments.findIndex(
      (comment) => comment.id === id,
    );

    if (matchingCommentIndex === -1) {
      this.appStore.UIStore.toast({
        status: "error",
      });
      captureEvent({
        message: "FE: Unable to find matching comment ID.",
      });
      return;
    }

    this.comments.splice(matchingCommentIndex, 1);
  }
}
