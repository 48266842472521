import { SimpleTaskFragment } from "@src/__generated__/graphql";
import { ModalTaskCreate } from "@src/components/widgets/Modals/ModalTask";
import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { ModalStore } from "@src/stores/ModalStore";
import { DisclosureState } from "@src/utils/mobx/states/DisclosureState";
import { OurWorkBudgetItemForLabel } from "@src/widgets/TaskSelect/TaskSelect";
import { makeObservable, observable } from "mobx";

type OpenOptionsBase = {
  onSubmit?: (data: SimpleTaskFragment) => void;
  hideCreateAllocationsCheckbox?: boolean;
  userId?: string;
};
type ParentTask = Pick<SimpleTaskFragment, "id" | "name" | "status"> & {
  ourWorkBudgetItem?: OurWorkBudgetItemForLabel | null;
};
type OpenOptionsExtra =
  | {
      projectId: string;
      budgetItemId: string;
      billable: boolean;
      parentTask: ParentTask;
      isTemplate: boolean;
      templateGroupId?: string;
      filterParentTasksForGroup?: boolean;
    }
  | {
      projectId?: string;
      budgetItemId?: string;
      billable?: boolean;
      parentTask?: ParentTask;
      isTemplate?: boolean;
      templateGroupId?: string;
      filterParentTasksForGroup?: boolean;
    };

export type TaskFormModalOpenOptions = OpenOptionsBase & OpenOptionsExtra;

export class TaskFormModalStore implements BaseStore, ModalStore {
  appStore: AppStore;
  readonly modalId = "taskFormModal";

  @observable modalState = new DisclosureState<TaskFormModalOpenOptions>({
    onClose: () => {
      this.appStore.UIStore.dialogs.closeModal(this.modalId);
    },
    onOpen: () => {
      this.appStore.UIStore.dialogs.openModal({
        id: this.modalId,
        content: <ModalTaskCreate />,
      });
    },
  });

  constructor(appStore: AppStore) {
    makeObservable(this);
    this.appStore = appStore;
  }
}
