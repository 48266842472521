import { IOption } from "@src/components/ui-kit";
import { ToClientBrandOptionsParams } from "./clientBrands";

export const toBrandsSectioned = (
  clientBrands: ToClientBrandOptionsParams["clientBrands"],
): IOption[] =>
  clientBrands.map((i) => ({
    value: i?.id,
    label: i?.name,
    options: i?.brands.map((brand) => {
      return {
        label: brand.name,
        value: brand.id,
      };
    })!,
  }));
