import {
  Box,
  BoxProps,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
} from "@chakra-ui/react";
import { t } from "@lingui/macro";
import { Icon } from "@src/components/ui-kit/Icon";
import { observer } from "mobx-react-lite";
import { FC, useState } from "react";
import { useDebounce } from "react-use";

type FiltersPanelSearchProps = {
  onSearch: (val: string) => void;
  searchPlaceholder?: string;
  inputWidth?: InputProps["width"];
} & BoxProps;

export const FiltersPanelSearch: FC<FiltersPanelSearchProps> = observer(
  function FiltersPanelSearch({
    onSearch,
    searchPlaceholder,
    inputWidth = "320px",
    ...props
  }) {
    const [searchTerm, setSearchTerm] = useState<undefined | string>(undefined);

    useDebounce(
      () => {
        if (searchTerm !== undefined && !!onSearch) onSearch(searchTerm);
      },
      1000,
      [searchTerm],
    );

    return (
      <Box {...props}>
        <InputGroup alignItems="center">
          <InputLeftElement h="9" pointerEvents="none">
            <Icon name="search-md" color="gray.600" />
          </InputLeftElement>
          <Input
            w={inputWidth}
            defaultValue={props.defaultValue}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder={searchPlaceholder ?? t`Search`}
            rounded="md"
            type="search"
          />
        </InputGroup>
      </Box>
    );
  },
);
