import {
  TaskForScopedUsersQuery,
  TaskQuery,
  User,
  UserScopeEnum,
} from "@src/__generated__/graphql";
import { MakeOptional } from "@src/utils/types";
import { compact } from "lodash";
import { action, makeObservable, observable } from "mobx";
import { PositionBadgeUser } from "../PositionBadgeWithHours";

type Positions = MakeOptional<
  TaskQuery["task"]["positions"][0],
  "planned_time" | "timeTrackingWorkType"
>[];
type OurWorkBudgetItem = MakeOptional<
  NonNullable<TaskQuery["task"]["ourWorkBudgetItem"]>,
  "title" | "timeTrackingSettings"
>;
type TTask = Omit<
  MakeOptional<
    TaskQuery["task"],
    | "billable"
    | "stats"
    | "commentCount"
    | "scopes"
    | "otherPeopleTracked"
    | "otherPeopleTrackedTotal"
    | "revision"
  >,
  "positions" | "ourWorkBudgetItem" | "parent"
> & {
  positions: Positions;
  ourWorkBudgetItem?: OurWorkBudgetItem | null;
  parent?:
    | TaskQuery["task"]["parent"]
    | TaskForScopedUsersQuery["task"]["parent"];
};
type TTaskPositionStat = TaskQuery["taskPositionStat"];

type AssignedUser = Omit<Partial<TTaskPositionStat[0]>, "user"> & {
  user?: PositionBadgeUser;
};
export class TaskModel {
  id: TTask["id"];
  name: TTask["name"];
  @observable status: TTask["status"];
  deadline: TTask["deadline"];
  from?: TTask["from"];
  to?: TTask["to"];
  @observable.ref description: TTask["description"];
  @observable positions: TTask["positions"];
  priority: TTask["priority"];
  stats: TTask["stats"];
  ourWorkBudgetItem: TTask["ourWorkBudgetItem"];
  @observable files: TTask["files"];
  createdBy: TTask["createdBy"];
  attachments_download_url: TTask["attachments_download_url"];
  @observable partners: TTask["users"];
  @observable clients: TTask["users"];

  userIdsWithEditPermission: User["id"][];
  @observable assignedUsers: AssignedUser[] = [];

  otherPeopleTrackedTotal?: TTask["otherPeopleTrackedTotal"];
  @observable.ref billable?: TTask["billable"];
  @observable linkedTasks: TTask["linkedTasks"];
  revision?: TTask["revision"];
  otherPeopleTracked: TTask["otherPeopleTracked"];
  cloudUrl: TTask["cloudUrl"];
  commentCount?: TTask["commentCount"];
  scopedCommentCount: TTask["scopedCommentCount"];
  parent?: TTask["parent"];
  subtasks?: TaskModel[];
  @observable is_template: TTask["is_template"];
  @observable task_template_group_id?: TTask["task_template_group_id"];

  constructor(task: TTask, positionsStats: TTaskPositionStat = []) {
    makeObservable(this);
    this.id = task.id;
    this.name = task.name;
    this.status = task.status;
    this.createdBy = task.createdBy;
    this.to = task.to ?? undefined;
    this.from = task.from ?? undefined;
    this.deadline = task.deadline;
    this.priority = task.priority;
    this.description = task.description;
    this.positions = task.positions;
    this.ourWorkBudgetItem = task.ourWorkBudgetItem;
    this.files = task.files;
    this.attachments_download_url = task.attachments_download_url;
    this.scopedCommentCount = task.scopedCommentCount;
    this.clients = task.users.filter(
      (i) => i.scope?.value === UserScopeEnum.Client,
    );
    this.partners = task.users.filter(
      (i) => i.scope?.value === UserScopeEnum.Partner,
    );
    this.userIdsWithEditPermission = compact([
      task.createdBy?.id,
      task.ourWorkBudgetItem?.project.projectManager.id,
      ...(task.ourWorkBudgetItem?.project.otherProjectManagers ?? []).map(
        ({ id }) => id,
      ),
    ]);

    for (const stat of positionsStats) {
      if (!stat) continue;
      this.assignedUsers.push(stat);
    }

    for (const partner of this.partners) {
      this.assignedUsers.push({
        user: partner,
        spent_time: undefined,
        planned_time: undefined,
        timeTrackingWorkType: undefined,
      });
    }

    for (const client of this.clients) {
      this.assignedUsers.push({
        user: client,
        spent_time: undefined,
        planned_time: undefined,
        timeTrackingWorkType: undefined,
      });
    }

    this.stats = task.stats;
    this.linkedTasks = task.linkedTasks;
    this.cloudUrl = task.cloudUrl;
    this.revision = task.revision;
    this.otherPeopleTracked = task.otherPeopleTracked;
    this.otherPeopleTrackedTotal = task.otherPeopleTrackedTotal;
    this.billable = task.billable;
    this.commentCount = task.commentCount;
    this.parent = task?.parent;
    this.subtasks =
      task.subtasks?.map((subtask) => new TaskModel(subtask)) ?? [];
    this.is_template = task.is_template;
    this.task_template_group_id = task?.task_template_group_id;
  }

  get deadlineDate() {
    return this.deadline ? new Date(this.deadline) : undefined;
  }

  @action.bound setDescription(value: TaskModel["description"]) {
    this.description = value;
  }

  @action.bound setFiles(files: TaskModel["files"]) {
    this.files = files;
  }

  @action.bound removeFile(publicId: TaskModel["files"][0]["public_id"]) {
    this.files = this.files.filter((i) => i.public_id !== publicId);
  }

  @action.bound setStatus(value: TaskModel["status"]) {
    this.status = value;
  }
}
