import { Center, HStack, Text } from "@chakra-ui/layout";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import { MultiMonthCalendar } from "@src/components/ui-kit";
import { currency } from "@src/utils/formatters";
import { useStore } from "@src/utils/hooks";
import { observer } from "mobx-react-lite";

export const ManualPaymentModal = observer(function ManualPaymentModal() {
  const { manualPaymentModalStore: store } = useStore();
  return (
    <Modal
      isOpen={store.disclosure.isOpen}
      onClose={store.disclosure.onClose}
      trapFocus={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader display="inline-flex">
          <Text fontWeight="inherit">
            <Trans>Select paid date</Trans>
          </Text>
          {store.selectedAccountMove && (
            <Text
              color="gray.600"
              fontSize="md"
              textAlign="center"
              marginY="auto"
            >
              &nbsp;
              <Trans>- Paid -</Trans>
              &nbsp;
              {currency.formatByWorkspace(store.selectedAccountMove.amount)}
            </Text>
          )}
          <ModalCloseButton isDisabled={store.mutator.isLoading.value} />
        </ModalHeader>
        <ModalBody>
          <Center>
            <MultiMonthCalendar
              singleDateSelection
              onSelectDate={store.handleSelectDate}
              value={store.date}
              hideCalendarActions
              months={1}
            />
          </Center>
        </ModalBody>
        <ModalFooter>
          <HStack justify="end" spacing="2">
            <Button
              colorScheme="grey"
              isLoading={store.isLoading.value}
              onClick={store.disclosure.onClose}
              variant="outline"
            >
              <Trans>Cancel</Trans>
            </Button>
            <Button
              isLoading={store.isLoading.value}
              onClick={store.handleConfirm}
            >
              <Trans>Mark as paid</Trans>
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
