import { Box, Heading, HStack, Stack, TabPanel } from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import { useGetTaskCommunicationFilesQuery } from "@src/__generated__/graphql";
import { AttachmentView } from "@src/components/widgets/Modals/ModalCommunication/components/Attachments/TaskAttachment";
import Comment from "@src/components/widgets/Modals/ModalCommunication/components/comment/Comment";
import { CommentAppearance } from "@src/components/widgets/Modals/ModalCommunication/components/comment/CommentAppearance";
import { SliderArrow } from "@src/components/widgets/Modals/ModalCommunication/components/tabs/SliderArrow";
import { UserType } from "@src/stores/models/Me";
import { onError } from "@src/utils/apollo";
import { useStore } from "@src/utils/hooks";
import useIntersectionObserver from "@src/utils/hooks/useIntersectionObserver";
import { userTypeToScope } from "@src/utils/userTypeToScope";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useRef, useState } from "react";

export type CommunicationTabProps = {
  userType: UserType;
};

const USER_TYPE_TO_COLOR_SCHEME_MAP = {
  client: "pink",
  internal: "grey",
  partner: "blue",
} as const;

export const CommunicationTab = observer(function CommunicationTab({
  userType,
}: CommunicationTabProps) {
  const { taskDetailModalStore: store } = useStore();
  const firstCommentRef = useRef(null);

  const { data: commentFiles } = useGetTaskCommunicationFilesQuery({
    variables: {
      task_id: store.taskId.value!,
      scope: userTypeToScope(userType),
    },
    context: {
      fetchOptions: {
        signal: null,
      },
    },
    ...onError(),
  });

  const attachmentsRef = useRef<HTMLDivElement>(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const { userTypesWithAccess } =
    store.communicationTabParentStore.selectedTabInfo;

  const checkScrollability = useCallback(() => {
    if (attachmentsRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = attachmentsRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollWidth > clientWidth);
    }
  }, []);

  useIntersectionObserver({
    threshold: 0.7,
    target: firstCommentRef,
    onIntersect: () => {
      if (
        store.communicationTabParentStore.comments.length === 0 ||
        store.seenLastComment.value
      )
        return;
      store.communicationTabParentStore.handleSeenLastComment();
    },
  });

  useEffect(() => {
    checkScrollability();
    window.addEventListener("resize", checkScrollability);
    return () => window.removeEventListener("resize", checkScrollability);
  }, [checkScrollability]);

  const scrollAttachments = useCallback(
    (direction: "left" | "right") => {
      if (attachmentsRef.current) {
        const container = attachmentsRef.current;
        const scrollAmount = container.clientWidth;
        const maxScroll = container.scrollWidth - container.clientWidth;

        let newScrollPosition =
          container.scrollLeft +
          (direction === "left" ? -scrollAmount : scrollAmount);
        newScrollPosition = Math.max(0, Math.min(newScrollPosition, maxScroll));

        container.scrollTo({
          left: newScrollPosition,
          behavior: "smooth",
        });

        // Update scroll buttons visibility after scrolling
        setTimeout(checkScrollability, 100);
      }
    },
    [checkScrollability],
  );

  return (
    <TabPanel px="0">
      <Box>
        {!!commentFiles?.taskCommentFiles.length && (
          <Stack mb="4">
            <HStack mb="2" px="40px">
              <Heading size="sm">
                <Trans>Attachments</Trans>
              </Heading>
            </HStack>
            <Box pos="relative">
              <SliderArrow
                visibility={canScrollLeft ? "visible" : "hidden"}
                direction="left"
                onClick={() => scrollAttachments("left")}
              />
              <HStack
                ref={attachmentsRef}
                overflowX="auto"
                px="40px"
                onScroll={checkScrollability}
                style={{ scrollbarWidth: "none" }}
              >
                {commentFiles?.taskCommentFiles.map((file) => (
                  <AttachmentView
                    key={file.public_id}
                    mimeType={file.mime_type}
                    w="16%"
                    minW="16%"
                    height="120"
                    id={file.public_id}
                    name={file.filename}
                    urls={file.urls}
                    removeDisabled
                  />
                ))}
              </HStack>
              <SliderArrow
                direction="right"
                visibility={canScrollRight ? "visible" : "hidden"}
                onClick={() => scrollAttachments("right")}
              />
            </Box>
          </Stack>
        )}

        <Box ref={store.commentsContainerRef}>
          {store.communicationTabParentStore.comments.map((comment, index) =>
            index === 0 ? (
              <CommentAppearance key={comment.id} commentId={comment.id}>
                <Comment
                  ref={firstCommentRef}
                  comment={comment}
                  onUpdated={
                    store.communicationTabParentStore.handleUpdateComment
                  }
                  onDeleted={
                    store.communicationTabParentStore.handleDeleteComment
                  }
                  taskId={store.task.value?.id}
                  colorScheme={USER_TYPE_TO_COLOR_SCHEME_MAP[userType]}
                  allowedMentions={userTypesWithAccess}
                />
              </CommentAppearance>
            ) : (
              <Comment
                key={comment.id}
                comment={comment}
                onUpdated={
                  store.communicationTabParentStore.handleUpdateComment
                }
                onDeleted={
                  store.communicationTabParentStore.handleDeleteComment
                }
                taskId={store.task.value?.id}
                colorScheme={USER_TYPE_TO_COLOR_SCHEME_MAP[userType]}
                allowedMentions={userTypesWithAccess}
              />
            ),
          )}
        </Box>
      </Box>
    </TabPanel>
  );
});
