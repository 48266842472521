import { Box, Stack, TabPanel, Text } from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import {
  Task,
  TaskQuery,
  useGetTaskLinkTypesQuery,
} from "@src/__generated__/urql-graphql";
import { IOption } from "@src/components/ui-kit";
import { TaskItemLinked } from "@src/components/ui-kit/TaskItem/TaskItemLinked";
import { LinkedTasksInput } from "@src/components/widgets/Modals/ModalCommunication/components/LinkedTasksInput";
import { useStore } from "@src/utils/hooks";
import { toOptions } from "@src/utils/map-to-options/toOptions";
import { groupBy } from "lodash";
import { observer, useLocalObservable } from "mobx-react-lite";
import { FunctionComponent, useEffect, useState } from "react";

export type TLinkedTasks = NonNullable<TaskQuery["task"]["linkedTasks"]>;

export const LinkedTasksTab: FunctionComponent = observer(
  function LinkedTasksTab() {
    const { taskDetailModalStore: store } = useStore();
    const [taskLinkTypes, setTaskLinkTypes] = useState<IOption[]>([]);
    const state = useLocalObservable(() => ({
      taskLinks: store.task.value?.linkedTasks || [],
      get taskLinksToRender() {
        return groupBy(this.taskLinks, "link_type.name");
      },
    }));

    const [{ data }] = useGetTaskLinkTypesQuery();

    useEffect(() => {
      if (data) {
        setTaskLinkTypes(toOptions(data.taskLinkTypes, "name"));
      }
    }, [data]);

    const onDeleteTask = (taskId: Task["id"]) => {
      const filteredTaskLinks = state.taskLinks?.filter(
        (item: TLinkedTasks[0]) => item.id !== taskId,
      );
      state.taskLinks = filteredTaskLinks;
    };

    const onCreateTask = (task: TLinkedTasks[0]) => {
      state.taskLinks = [...state.taskLinks, task];
    };

    const onTaskClick = (id: Task["id"]) => {
      window.open(`${location.origin}/notifications?taskId=${id}`, "_blank");
    };

    return (
      <TabPanel px="8">
        <Stack>
          {!!taskLinkTypes.length && store.taskId.value && (
            <LinkedTasksInput
              taskLinkTypes={taskLinkTypes}
              taskId={store.taskId.value}
              onCreateTask={onCreateTask}
            />
          )}
          <Stack spacing="4">
            {!!Object.keys(state.taskLinksToRender).length ? (
              Object.keys(state.taskLinksToRender).map((key) => (
                <Box key={key}>
                  <Text mb="2" fontWeight="medium">
                    {key}
                  </Text>
                  <Stack spacing="1">
                    {state.taskLinksToRender[key].map(
                      (task: TLinkedTasks[0]) => (
                        <TaskItemLinked
                          // NOTE: Why should we ignore this???? Fix typing omg
                          // Can safely ignore because missing items aren't used in rendering
                          // @ts-expect-error
                          task={task.linked_task}
                          key={task.linked_task.id}
                          onClick={({ id }) => {
                            onTaskClick(id);
                          }}
                          onDelete={(id) => onDeleteTask(id)}
                          linkedTaskId={task.id}
                        />
                      ),
                    )}
                  </Stack>
                </Box>
              ))
            ) : (
              <Text>
                <Trans>No linked tasks</Trans>
              </Text>
            )}
          </Stack>
        </Stack>
      </TabPanel>
    );
  },
);
