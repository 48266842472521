import { plural, t } from "@lingui/macro";
import { captureEvent } from "@sentry/nextjs";
import { StartDayOfWeekEnum } from "@src/__generated__/urql-graphql";
import { getSymbolFromCode } from "@src/utils/currency/currency-symbol-map";
import { convertTimeWithSecondsToTime } from "@src/utils/time";
import { TimeWithSeconds } from "@src/utils/types";
import { Calendar } from "@uselessdev/datepicker";
import { computed, makeObservable, observable } from "mobx";
import { TWorkspaceSettings } from "./types";

const localeDefault = "sk-SK";

export class WorkspaceSettings {
  title: TWorkspaceSettings["title"];
  country: TWorkspaceSettings["country"];
  currency: TWorkspaceSettings["currency"];
  defaultVatRate: TWorkspaceSettings["defaultVatRate"];
  default_payment_due: TWorkspaceSettings["default_payment_due"];
  time_tracking_rate_type: TWorkspaceSettings["time_tracking_rate_type"];
  time_tracking_rate_length: TWorkspaceSettings["time_tracking_rate_length"];
  exchange_rate_denomination: TWorkspaceSettings["exchange_rate_denomination"];
  moduleSettings: TWorkspaceSettings["moduleSettings"];
  exportSettings: TWorkspaceSettings["exportSettings"];
  generate_numerical_brand_codes: TWorkspaceSettings["generate_numerical_brand_codes"];
  transfer_credit_directly_from_invoice: TWorkspaceSettings["transfer_credit_directly_from_invoice"];
  require_two_factor_authentication: TWorkspaceSettings["require_two_factor_authentication"];
  invoicing_more_teams: TWorkspaceSettings["invoicing_more_teams"];
  can_transfer_credit_with_date_without_constraints: TWorkspaceSettings["can_transfer_credit_with_date_without_constraints"];
  default_task_status_id: TWorkspaceSettings["default_task_status_id"];
  default_task_priority_id: TWorkspaceSettings["default_task_priority_id"];
  automatically_approved_expense_items: TWorkspaceSettings["automatically_approved_expense_items"];
  setup_completed: TWorkspaceSettings["setup_completed"];
  can_close_expense_budget_item_with_unapproved_expenses: TWorkspaceSettings["can_close_expense_budget_item_with_unapproved_expenses"];
  show_expense_item_vat_rate: TWorkspaceSettings["show_expense_item_vat_rate"];
  trial_from: TWorkspaceSettings["trial_from"];
  default_user_plannable_capacity: TWorkspaceSettings["default_user_plannable_capacity"];
  working_from: TWorkspaceSettings["working_from"];
  working_to: TWorkspaceSettings["working_to"];
  can_skip_invoice_date_validations: TWorkspaceSettings["can_skip_invoice_date_validations"];
  @observable
  currencyExchangeRatesStructured: TWorkspaceSettings["currencyExchangeRatesStructured"];
  @observable default_planning_view_settings: typeof DEFAULT_PLANNING_SETTING;
  @observable users_planning_view_settings: Omit<
    typeof DEFAULT_PLANNING_SETTING,
    "allocation_item_style"
  >;
  specific_time_in_planning_enabled: TWorkspaceSettings["specific_time_in_planning_enabled"];
  timetastic_integration: TWorkspaceSettings["timetastic_integration"];
  show_done_tasks: TWorkspaceSettings["show_done_tasks"];
  planning_alphabet_user_order: TWorkspaceSettings["planning_alphabet_user_order"];
  hide_expense_summary: TWorkspaceSettings["hide_expense_summary"];
  disable_home_office_in_planning: TWorkspaceSettings["disable_home_office_in_planning"];
  hide_planning_total_daily_capacity: TWorkspaceSettings["hide_planning_total_daily_capacity"];
  @observable start_day_of_week: TWorkspaceSettings["start_day_of_week"];
  @observable.ref xero_enabled: TWorkspaceSettings["xero_enabled"];
  @observable.ref
  default_utilization_range_from: TWorkspaceSettings["default_utilization_range_from"];
  @observable.ref
  default_utilization_range_to: TWorkspaceSettings["default_utilization_range_to"];
  @observable.ref
  auto_deadline_in_tasks: TWorkspaceSettings["auto_deadline_in_tasks"];
  @observable.ref
  budget_pdf_export_settings: TWorkspaceSettings["budget_pdf_export_settings"];
  @observable.ref
  budget_xlsx_languages: TWorkspaceSettings["budget_xlsx_languages"];
  @observable locale: TWorkspaceSettings["locale"] = localeDefault;
  @observable vapid_public_key: TWorkspaceSettings["vapid_public_key"];
  @observable.ref
  defaultShowUnitPriceOnInvoices: TWorkspaceSettings["default_show_unit_price_on_invoices"];
  @observable.ref
  defaultShowBillingAccountingOnInvoices: TWorkspaceSettings["default_show_billing_accounting_on_invoices"];
  @observable.ref
  automateInvoicingPlanApproval: TWorkspaceSettings["automate_invoicing_plan_approval"];
  @observable.ref enableBank: TWorkspaceSettings["enable_bank"];
  @observable.ref bankApiActive: TWorkspaceSettings["bank_api_active"];

  constructor(src: TWorkspaceSettings) {
    makeObservable(this);
    this.title = src.title;
    this.country = src.country;
    this.currency = src.currency;
    this.defaultVatRate = src.defaultVatRate;
    this.default_payment_due = src.default_payment_due;
    this.time_tracking_rate_type = src.time_tracking_rate_type;
    this.time_tracking_rate_length = src.time_tracking_rate_length;
    this.exchange_rate_denomination = src.exchange_rate_denomination;
    this.generate_numerical_brand_codes = src.generate_numerical_brand_codes;
    this.transfer_credit_directly_from_invoice =
      src.transfer_credit_directly_from_invoice;
    this.moduleSettings = src.moduleSettings;
    this.exportSettings = src.exportSettings;
    this.require_two_factor_authentication =
      src.require_two_factor_authentication;
    this.invoicing_more_teams = src.invoicing_more_teams;
    this.can_transfer_credit_with_date_without_constraints =
      src.can_transfer_credit_with_date_without_constraints;
    this.default_task_priority_id = src.default_task_priority_id;
    this.default_task_status_id = src.default_task_status_id;
    this.automatically_approved_expense_items =
      src.automatically_approved_expense_items;
    this.setup_completed = src.setup_completed;
    this.can_close_expense_budget_item_with_unapproved_expenses =
      src.can_close_expense_budget_item_with_unapproved_expenses;
    this.currencyExchangeRatesStructured = src.currencyExchangeRatesStructured;
    this.show_expense_item_vat_rate = src.show_expense_item_vat_rate;
    this.trial_from = src.trial_from;
    this.default_user_plannable_capacity = src.default_user_plannable_capacity;
    this.specific_time_in_planning_enabled =
      src.specific_time_in_planning_enabled;
    this.default_planning_view_settings = parsePlanningViewSettings(
      src.planning_view_settings,
    );
    this.users_planning_view_settings = getSavedPlanningViewSettings(
      parsePlanningViewSettings(src.planning_view_settings),
    );
    this.working_from = convertTimeWithSecondsToTime(
      src.working_from as TimeWithSeconds,
    );
    this.working_to = convertTimeWithSecondsToTime(
      src.working_to as TimeWithSeconds,
    );
    this.timetastic_integration = src.timetastic_integration;
    this.can_skip_invoice_date_validations =
      src.can_skip_invoice_date_validations;
    this.show_done_tasks = src.show_done_tasks;
    this.planning_alphabet_user_order = src.planning_alphabet_user_order;
    this.hide_expense_summary = src.hide_expense_summary;
    this.disable_home_office_in_planning = src.disable_home_office_in_planning;
    this.hide_planning_total_daily_capacity =
      src.hide_planning_total_daily_capacity;
    this.start_day_of_week = src.start_day_of_week;
    this.xero_enabled = src.xero_enabled;
    this.default_utilization_range_from = src.default_utilization_range_from;
    this.default_utilization_range_to = src.default_utilization_range_to;
    this.auto_deadline_in_tasks = src.auto_deadline_in_tasks;
    this.budget_pdf_export_settings = src.budget_pdf_export_settings;
    this.budget_xlsx_languages = src.budget_xlsx_languages;
    this.locale = src.locale ?? localeDefault;
    this.vapid_public_key = src.vapid_public_key;
    this.defaultShowUnitPriceOnInvoices =
      src.default_show_unit_price_on_invoices;
    this.defaultShowBillingAccountingOnInvoices =
      src.default_show_billing_accounting_on_invoices;
    this.automateInvoicingPlanApproval = src.automate_invoicing_plan_approval;
    this.enableBank = src.enable_bank;
    this.bankApiActive = src.bank_api_active;
  }

  get currency_symbol() {
    return getSymbolFromCode(this.currency.code);
  }

  @computed get startOfWeekNumber(): Calendar["weekStartsOn"] {
    return this.start_day_of_week === StartDayOfWeekEnum.Sunday ? 0 : 1;
  }

  /**
   * This function doesn't convert value, just formats.
   */
  timeTrackingRateValueReadable(value: number, hoursText?: string): string {
    value = this.formatTimeTrackingRateValue(value);

    if (this.time_tracking_rate_type === "man-day") {
      return plural(value, { one: `# MD`, other: `# MDs` });
    }

    if (hoursText) {
      return `${value}${hoursText}`;
    }

    return plural(value, {
      one: "# hour",
      other: "# hours",
    });
  }

  timeTrackingRateTypeReadable(): string {
    return this.time_tracking_rate_type === "man-day"
      ? t`Tracked MDs`
      : this.time_tracking_rate_type === "man-hour"
        ? t`Tracked hours`
        : "";
  }

  convertTimeTrackingRate(seconds: number, format = true): number {
    const value = seconds / this.time_tracking_rate_length;
    return format ? this.formatTimeTrackingRateValue(value) : value;
  }

  formatTimeTrackingRateValue(value: number, decimals = 2): number {
    return +value.toFixed(decimals);
  }
}

type TPlanningViewSettings = {
  planning_view_settings: {
    name: MY_SETTINGS_PROPERTIES;
    value: string;
  }[];
};

export function getSavedPlanningViewSettings(
  defaultSettings: typeof DEFAULT_PLANNING_SETTING,
) {
  let savedSettings: TPlanningViewSettings | undefined;

  const localStorageString = localStorage.getItem("planning_view_settings");
  if (localStorageString) {
    try {
      savedSettings = JSON.parse(localStorageString) as TPlanningViewSettings;
    } catch {
      captureEvent({
        message: "Failed to parse planning_view_settings from localStorage",
        extra: { localStorageString },
      });
    }
  }

  if (!savedSettings) {
    savedSettings = {
      planning_view_settings: Object.values(MY_SETTINGS_PROPERTIES).map(
        (key) => ({
          name: key,
          value: "default",
        }),
      ),
    };
    localStorage.setItem(
      "planning_view_settings",
      JSON.stringify(savedSettings),
    );
  }

  const result: Record<string, any> = {};

  savedSettings.planning_view_settings.forEach(({ value, name }) => {
    if (value === "default") {
      result[name] = defaultSettings[name];
      return;
    }
    if (value === "true") {
      result[name] = true;
      return;
    }
    if (value === "false") {
      result[name] = false;
      return;
    }
    if (name === "text_order") {
      result[name] = value.split(",");
      return;
    }

    result[name] = value;
  });

  return result as unknown as Omit<
    typeof DEFAULT_PLANNING_SETTING,
    "allocation_item_style"
  >;
}

export function parsePlanningViewSettings(
  savedSettings: TWorkspaceSettings["planning_view_settings"],
) {
  const settings = { ...DEFAULT_PLANNING_SETTING };

  savedSettings?.map((setting) => {
    if (settings.hasOwnProperty(setting.name)) {
      let parsedValue: unknown = setting.value;
      if (setting.value === "true") {
        parsedValue = true;
      }
      if (setting.value === "false") {
        parsedValue = false;
      }
      if (setting.name === "text_order") {
        parsedValue = setting.value.split(",");
      }
      // @ts-expect-error
      settings[setting.name] = parsedValue;
    }
  });

  return settings;
}

export enum PLANNING_SETTING_PROPERTIES {
  SHOW_TASK = "show_task",
  SHOW_PROJECT = "show_project",
  SHOW_BUDGET_ITEM = "show_budget_item",
  SHOW_NOTE = "show_note",
  ADJUST_ALLOCATION_HEIGHT_BY_DURATION = "adjust_allocation_height_by_duration",
  ALLOCATION_ITEM_STYLE = "allocation_item_style",
  TEXT_ORDER = "text_order",
  VIEW_TYPE = "view_type",
}

export enum MY_SETTINGS_PROPERTIES {
  SHOW_TASK = "show_task",
  SHOW_PROJECT = "show_project",
  SHOW_BUDGET_ITEM = "show_budget_item",
  SHOW_NOTE = "show_note",
  ADJUST_ALLOCATION_HEIGHT_BY_DURATION = "adjust_allocation_height_by_duration",
  TEXT_ORDER = "text_order",
}

export enum AllocationItemStyleEnum {
  COLOR_SUBTLE = "color-subtle",
  COLOR_FILL = "color-fill",
  NO_COLOR = "no-color",
}

export enum PlanningViewTypeEnum {
  WEEK_START = "week-start",
  WEEK_FROM_NOW = "week-from-now",
}

export const DEFAULT_PLANNING_SETTING = {
  [PLANNING_SETTING_PROPERTIES.SHOW_TASK]: true,
  [PLANNING_SETTING_PROPERTIES.SHOW_PROJECT]: true,
  [PLANNING_SETTING_PROPERTIES.SHOW_BUDGET_ITEM]: true,
  [PLANNING_SETTING_PROPERTIES.SHOW_NOTE]: true,
  [PLANNING_SETTING_PROPERTIES.ADJUST_ALLOCATION_HEIGHT_BY_DURATION]: true,
  [PLANNING_SETTING_PROPERTIES.ALLOCATION_ITEM_STYLE]:
    AllocationItemStyleEnum.COLOR_SUBTLE as AllocationItemStyleEnum,
  [PLANNING_SETTING_PROPERTIES.TEXT_ORDER]: [
    PLANNING_SETTING_PROPERTIES.SHOW_PROJECT,
    PLANNING_SETTING_PROPERTIES.SHOW_TASK,
    PLANNING_SETTING_PROPERTIES.SHOW_BUDGET_ITEM,
  ],
  [PLANNING_SETTING_PROPERTIES.VIEW_TYPE]:
    PlanningViewTypeEnum.WEEK_FROM_NOW as PlanningViewTypeEnum,
} as const;
