import { Scalars, UsersWhereColumn } from "@src/__generated__/graphql";
import {
  GetDefaultUserMentionsDocument,
  GetDefaultUserMentionsQuery,
  GetDefaultUserMentionsQueryVariables,
  SqlOperator,
  UsersSimpleMapDocument,
  UsersSimpleMapQuery,
  UsersSimpleMapQueryVariables,
} from "@src/__generated__/urql-graphql";
import {
  MentionSuggestionsList,
  MentionSuggestionsListRef,
} from "@src/components/ui-kit/TextEditor/extensions/MentionSuggestionsList";
import { client } from "@src/services/client";
import { appStore } from "@src/stores/AppStore";
import { UserType } from "@src/stores/models/Me";
import { Filter, Filters } from "@src/utils/components/filters/models";
import { useStore } from "@src/utils/hooks";
import { userTypeToScope } from "@src/utils/userTypeToScope";
import { Editor } from "@tiptap/core";
import Mention from "@tiptap/extension-mention";
import { ReactRenderer } from "@tiptap/react";
import { SuggestionOptions } from "@tiptap/suggestion";
import tippy, { Instance } from "tippy.js";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    mention: {
      getUserMentionIds: () => string[];
      getTeamMentionIds: () => string[];
      getAssigneesMention: () => boolean;
    };
  }
}

const ASSIGNEES_MENTION_ID = "task";
const ASSIGNEES_MENTION_NAME = "Task";
const ASSIGNEES_MENTION_PREFIX = "assignees-";
const USER_MENTION_PREFIX = "user-";
const TEAM_MENTION_PREFIX = "team-";

export const MentionExtension = (
  allowedMentions: UserType[],
  taskId?: Scalars["ID"]["input"],
) => {
  const { authStore } = useStore();
  return Mention.extend({
    // @ts-expect-error
    addCommands() {
      return {
        getUserMentionIds: () => () =>
          getMentionIds(this.editor, USER_MENTION_PREFIX),
        getTeamMentionIds: () => () =>
          getMentionIds(this.editor, TEAM_MENTION_PREFIX),
        getAssigneesMention: () => () =>
          getMentionIds(this.editor, ASSIGNEES_MENTION_PREFIX).length > 0,
      };
    },
    addAttributes() {
      return {
        ...this.parent?.(),
        user: {
          default: null,
          renderHTML: (attributes) => {
            if (
              authStore.user?.id !==
              attributes.id
                .replace(USER_MENTION_PREFIX, "")
                .replace(TEAM_MENTION_PREFIX, "")
                .replace(ASSIGNEES_MENTION_PREFIX, "")
            ) {
              return {};
            }
            return { class: "my-mention" };
          },
        },
      };
    },
  }).configure({
    HTMLAttributes: {
      class: "mention",
    },
    renderHTML({ options, node }) {
      const attrId = node.attrs.id
        .replace(USER_MENTION_PREFIX, "")
        .replace(TEAM_MENTION_PREFIX, "")
        .replace(ASSIGNEES_MENTION_PREFIX, "");

      return [
        "span",
        {
          "data-id": node.attrs.id,
          "data-type": "mention",
          "data-label": node.attrs.label,
          class:
            // eslint-disable-next-line lingui/no-unlocalized-strings
            authStore.user?.id === attrId ? "mention my-mention" : "mention",
          contenteditable: "false",
        },
        `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`,
      ];
    },
    suggestion: {
      // TODO allow space bugfix https://github.com/ueberdosis/tiptap/issues/214#issuecomment-964557956
      // allowSpaces: true,
      // TODO debounce
      items: async ({ query }) => {
        const suggestions: Array<UsersSimpleMapQuery["userSimpleMap"][0]> = [];
        const teamCount = appStore.workspaceStore.teams.length;

        if (query.length < 1) {
          suggestions.push({
            id: ASSIGNEES_MENTION_PREFIX + ASSIGNEES_MENTION_ID,
            full_name: ASSIGNEES_MENTION_NAME,
          });

          try {
            const where = new Filters<UsersWhereColumn>([
              new Filter({
                column: UsersWhereColumn.Scope,
                operator: SqlOperator.In,
                options: [],
                value: allowedMentions.map((m) => userTypeToScope(m)),
              }),
            ]);

            // TODO: we should fetch this in the beginning (page load query with auth user and other stuff?) so user don't need to wait for it
            const usersData = await client
              .query<
                GetDefaultUserMentionsQuery,
                GetDefaultUserMentionsQueryVariables
              >(GetDefaultUserMentionsDocument, {
                where: where.asWhereParam,
                taskId,
              })
              .toPromise()
              .then(({ data }) => data?.userSimpleMap ?? []);

            const userCount = usersData.length;
            for (let i = 0; i < userCount; i++) {
              const user = usersData[i];

              suggestions.push({
                id: USER_MENTION_PREFIX + user.id,
                full_name: user.full_name,
                image: user.image,
              });
            }
          } catch (e) {}

          for (let i = 0; i < teamCount; i++) {
            const team = appStore.workspaceStore.teams[i];

            suggestions.push({
              id: TEAM_MENTION_PREFIX + team.id,
              full_name: team.name,
            });
          }
        } else {
          // temp workaround of allowsSpaces
          query = query.replaceAll("_", " ");
          // Regexp for task mention
          const regexpForAll = new RegExp(/^ta?s?k?$/g);

          if (!!query.toLowerCase().match(regexpForAll)) {
            suggestions.push({
              id: ASSIGNEES_MENTION_PREFIX + ASSIGNEES_MENTION_ID,
              full_name: ASSIGNEES_MENTION_NAME,
            });
          }

          try {
            const usersData = await client
              .query<UsersSimpleMapQuery, UsersSimpleMapQueryVariables>(
                UsersSimpleMapDocument,
                {
                  search: query,
                  allowedScopes: allowedMentions.map((m) => userTypeToScope(m)),
                  taskId,
                },
              )
              .toPromise()
              .then(({ data }) => data?.userSimpleMap ?? []);

            const userCount = usersData.length;
            for (let i = 0; i < userCount; i++) {
              const user = usersData[i];

              suggestions.push({
                id: USER_MENTION_PREFIX + user.id,
                full_name: user.full_name,
                image: user.image,
              });
            }

            for (let i = 0; i < teamCount; i++) {
              const team = appStore.workspaceStore.teams[i];

              if (!team.name.toLowerCase().includes(query.toLowerCase()))
                continue;

              suggestions.push({
                id: TEAM_MENTION_PREFIX + team.id,
                full_name: team.name,
              });
            }
          } catch (e) {}
        }

        return suggestions;
      },

      render: suggestionsRenderer,
    },
  });
};

function getMentionIds(editor: Editor, prefix: string): string[] {
  const mentionIDs: string[] = [];
  const parser = new DOMParser();
  const htmlString = editor.getHTML();
  const htmlDocument = parser.parseFromString(htmlString, "text/html");
  const mentions = htmlDocument.querySelectorAll(".mention");

  mentions.forEach((mentionTag) => {
    const mentionID = mentionTag.getAttribute("data-id");
    if (mentionID && mentionID.includes(prefix)) {
      mentionIDs.push(mentionID.replace(prefix, ""));
    }
  });

  return mentionIDs;
}

const suggestionsRenderer: SuggestionOptions["render"] = () => {
  let reactRenderer: ReactRenderer<MentionSuggestionsListRef>;
  let popup: Instance[] | undefined;

  return {
    onStart: (props) => {
      reactRenderer = new ReactRenderer(MentionSuggestionsList, {
        props,
        editor: props.editor,
      });

      // @ts-expect-error
      popup = tippy("body", {
        getReferenceClientRect: props.clientRect,
        appendTo: () => document.body,
        content: reactRenderer.element,
        showOnCreate: true,
        interactive: true,
        trigger: "manual",
        placement: "bottom-start",
        maxWidth: "500px",
        arrow: false,
        theme: "unstyled",
      });
    },
    onUpdate(props) {
      reactRenderer?.updateProps(props);

      popup?.forEach((tippy) => {
        tippy.setProps({
          // @ts-expect-error
          getReferenceClientRect: props.clientRect,
        });
      });
    },
    onKeyDown(props) {
      if (props.event.key === "Escape") {
        popup?.forEach((tippy) => tippy.hide());
        props.event.stopPropagation();

        return true;
      }

      if (!reactRenderer?.ref) {
        return false;
      }

      return reactRenderer.ref.onKeyDown(props);
    },

    onExit() {
      popup?.forEach((tippy) => tippy.destroy());
      reactRenderer?.destroy();
    },
  };
};
