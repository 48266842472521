import { formatDate } from "@components/ui-kit";
import { t } from "@lingui/macro";
import { appStore } from "@src/stores/AppStore";
import { filesize } from "filesize";
import { padStart, upperCase } from "lodash";
import m from "mime-types";
import { format as _formatPhone } from "phone-fns";
export { formatDate } from "@components/ui-kit";
export * from "./currency";
export { currency } from "./currency";
export { formatIBAN } from "./formatIBAN";
export { denominate, nominate } from "./helpers";

export const ONE_HOUR_IN_SECONDS = 3600;
export const HOURS_IN_ONE_DAY = 23.99;
export const HUNDRED_PERCENT = 10000;

export const lazyround = function (num: string) {
  const numberWithOrder = (num: number) => {
    if (num >= 1.0e9) {
      return { number: num / 1.0e9, label: t` Mld.` };
    } else if (num >= 1.0e6) {
      return { number: num / 1.0e6, label: t` Mil.` };
    } else if (num >= 1.0e3) {
      return { number: num / 1.0e3, label: " K" };
    }

    return { number: num, label: "" };
  };

  const { number, label } = numberWithOrder(Number(num) / 100);
  const formattedNumber = new Intl.NumberFormat(
    appStore.workspaceStore.settings?.locale ?? "sk-SK",
    {
      maximumSignificantDigits: 2,
    },
  ).format(number);
  return `${formattedNumber} ${label}`;
};

export const percentFormatter = () =>
  new Intl.NumberFormat(appStore.workspaceStore.settings?.locale ?? "sk-SK", {
    style: "percent",
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
  });

export const percent = {
  format: (number: number | string) =>
    percentFormatter().format(+number / 10000),
};

export const inputPercent = {
  format: (percent: number | string) => +percent / 100,
};

export const dateTime = {
  format: (date?: Date) => formatDate(date),
  formatFromString: (date: string) => formatDate(new Date(date)),
};

export const formatPhone = (value: string) => {
  return _formatPhone("+NNN NNN NNN NNN", value);
};

export const formatFileType = (val: File["type"]) => {
  return upperCase(m.extension(val) || "");
};

export const formatFileSize = (val: File["size"]) => {
  return filesize(val, { round: 0, spacer: "" });
};

/**
 *
 * @param time - seconds
 */
export function formatDuration(
  time: number,
  opts?: { noPadStart?: boolean; roundSeconds?: boolean },
) {
  const seconds = opts?.roundSeconds ? Math.round(time / 100) * 100 : time;
  // Hours, minutes and seconds
  const hrs = Math.floor(seconds / ONE_HOUR_IN_SECONDS);
  const mins = Math.floor((seconds % ONE_HOUR_IN_SECONDS) / 60);
  // var secs = ~~time % 60

  let hrsString = String(hrs);
  let minsString = String(mins);

  if (!opts?.noPadStart) {
    hrsString = padStart(hrsString, 2, "0");
    minsString = padStart(minsString, 2, "0");
  } else {
    minsString = padStart(minsString, 2, "0");
  }

  return [hrsString, minsString].join(":");
}
