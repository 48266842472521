import { Box, keyframes, useToken } from "@chakra-ui/react";
import { TaskComment } from "@src/__generated__/urql-graphql";
import { useStore } from "@src/utils/hooks";
import { observer } from "mobx-react-lite";
import React, { FunctionComponent } from "react";

export const CommentAppearance: FunctionComponent<
  React.PropsWithChildren<{ commentId: TaskComment["id"] }>
> = observer(function CommentAppearance({ commentId, children }) {
  const { taskDetailModalStore: store } = useStore();
  const [white, yellow200] = useToken("colors", ["white", "yellow.200"]);
  const newComment = keyframes`
      from {
        background-color: ${yellow200};
      }
      to {
        background-color: ${white};
      }
    `;
  // eslint-disable-next-line lingui/no-unlocalized-strings
  const newCommentAnimation = `${newComment} 1 1.5s linear`;

  return (
    <Box
      animation={
        store.newlyCreatedCommentID === commentId
          ? newCommentAnimation
          : undefined
      }
    >
      {children}
    </Box>
  );
});
