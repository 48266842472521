import { IconButton } from "@chakra-ui/react";
import { t } from "@lingui/macro";
import { useDeleteLinkTaskMutation } from "@src/__generated__/graphql";
import { InternalTaskType } from "@src/components/modules/resource-planning/tasks/listing/TasksListingStore";
import { trackEvent } from "@src/services/amplitude";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { Icon } from "../Icon";
import { TaskItemBase, TaskItemBaseProps } from "./TaskItemBase";

type TTaskItemLinkedProps = Omit<
  TaskItemBaseProps<InternalTaskType>,
  "content" | "title"
> & {
  linkedTaskId: string;
  onDelete: (taskId: string) => void;
};

export const TaskItemLinked: FC<TTaskItemLinkedProps> = observer(
  function TaskItemLinked(props) {
    const [deleteLinkTask, { loading: deleteLoading }] =
      useDeleteLinkTaskMutation({
        onCompleted(data) {
          if (data.deleteTaskLink) {
            props.onDelete?.(data.deleteTaskLink.id);
            trackEvent("task", "Deleted task link");
          }
        },
      });

    const onDelete = async () => {
      await deleteLinkTask({
        variables: {
          task_link_id: props.linkedTaskId,
        },
      });
    };

    return (
      <TaskItemBase
        title={props.task?.name ?? ""}
        wrapperStyle={{
          gridTemplateColumns:
            "9rem 5rem 1fr max-content max-content max-content",
        }}
        content={() => <div />}
        extraContent={() => (
          <IconButton
            aria-label={t`delete linked task`}
            colorScheme="grey"
            isLoading={deleteLoading}
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
            size="sm"
            variant="ghost"
          >
            <Icon name="x-close" w="4" h="4" />
          </IconButton>
        )}
        {...props}
      />
    );
  },
);
