import {
  SimpleTaskForClientFragment,
  SimpleTaskFragment,
  Task,
  TaskComment,
  TaskDocument,
  TaskForScopedUsersDocument,
  TaskForScopedUsersQuery,
  TaskForScopedUsersQueryVariables,
  TaskQuery,
  TaskQueryVariables,
} from "@src/__generated__/graphql";
import { IOption } from "@src/components/ui-kit";
import { TASK_ID_QUERY_KEY } from "@src/components/widgets/Modals/ModalCommunication/CommunicationModalHeader";
import { ModalCommunication } from "@src/components/widgets/Modals/ModalCommunication/ModalCommunication";
import { TaskModel } from "@src/components/widgets/Modals/ModalCommunication/models";
import { TaskTabIdEnum } from "@src/constants/tasks";
import { FetchHelper } from "@src/helpers/apollo/fetch";
import { trackEvent } from "@src/services/amplitude";
import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { ModalStore } from "@src/stores/ModalStore";
import { BooleanState } from "@src/utils/mobx/states/BooleanState";
import { DisclosureState } from "@src/utils/mobx/states/DisclosureState";
import { UnionState } from "@src/utils/mobx/states/UnionState";
import { ValueState } from "@src/utils/mobx/states/ValueState";
import { GraphQLError } from "graphql";
import { omit } from "lodash";
import { action, computed, makeObservable, observable } from "mobx";
import router from "next/router";
import React, { createRef } from "react";
import { CommunicationTabParentStore } from "./components/tabs/communication-tab-parent-store";

type DescriptionState = "write" | "read";
type ModalCommunicationOptions = {
  id: Task["id"];
  onChange?: (task: SimpleTaskFragment | SimpleTaskForClientFragment) => void;
  onDelete?: (task: TaskModel) => void;
  onDuplicate?: (task: SimpleTaskFragment) => void;
  onTimeTrackingItemCreated?: (trackedTime: number | undefined) => void;
};

// TODO: rename store file to `TaskDetailModalStore` after Forecasting merge to avoid conflicts
export class ModalCommunicationStore implements BaseStore, ModalStore {
  appStore: AppStore;

  readonly modalId = "taskDetailModal";

  drawerState = new DisclosureState<ModalCommunicationOptions>({
    onOpen: (additionalData) => {
      trackEvent("task", "Opening task drawer");
      this.appStore.UIStore.dialogs.openModal({
        id: this.modalId,
        content: <ModalCommunication />,
      });
      if (!additionalData?.id) return;
      this.taskId.set(additionalData?.id);
      this.descriptionState.set("read");
      this.communicationTabParentStore.reset();
      this.fetchTask();

      if (!router.query[TASK_ID_QUERY_KEY]) {
        router.replace(
          {
            pathname: router.pathname,
            query: {
              ...router.query,
              [TASK_ID_QUERY_KEY]: additionalData?.id,
            },
          },
          undefined,
          { shallow: true },
        );
      }
    },
    onClose: () => {
      this.appStore.UIStore.dialogs.closeModal(this.modalId);
      router.replace(
        {
          pathname: router.pathname,
          query: omit(router.query, TASK_ID_QUERY_KEY),
        },
        undefined,
        { shallow: true },
      );
    },
  });

  addClientDisclosure = new DisclosureState();
  addPartnerDisclosure = new DisclosureState();
  addInternalUserDisclosure = new DisclosureState();

  task = new ValueState<TaskModel | null>(null);
  taskStatuses = new ValueState<TaskQuery["taskStatuses"]>([]);
  taskPriorities = new ValueState<TaskQuery["taskPriorities"]>([]);
  activeTabId = new ValueState<TaskTabIdEnum>(TaskTabIdEnum.Description);
  taskId = new ValueState<string | undefined>(undefined);
  attachmentsCollapsed = new BooleanState();
  isLoading = new BooleanState();
  isLoadingDuplicate = new BooleanState();
  @observable priorityOptions: IOption[] = [];

  deleteModal = new DisclosureState();
  editTaskModal = new DisclosureState<{ moveMode: boolean }>();

  commentsContainerRef: React.RefObject<HTMLDivElement> = createRef();
  drawerBodyRef = createRef<HTMLDivElement>();

  @observable newlyCreatedCommentID?: TaskComment["id"] = undefined;
  seenLastComment = new BooleanState();
  needsRevision = new BooleanState();

  @observable descriptionLoading = new BooleanState(false);

  descriptionState = new UnionState<DescriptionState>("read");
  @observable descriptionChanged = false;
  @observable commentChanged = false;

  communicationTabParentStore: CommunicationTabParentStore;

  internalUserTaskFetcher = new FetchHelper<TaskQuery, TaskQueryVariables>(
    TaskDocument,
  );
  scopedUserTaskFetcher = new FetchHelper<
    TaskForScopedUsersQuery,
    TaskForScopedUsersQueryVariables
  >(TaskForScopedUsersDocument);

  constructor(appStore: AppStore) {
    makeObservable(this);
    this.appStore = appStore;
    this.communicationTabParentStore = new CommunicationTabParentStore(
      appStore,
    );
  }

  @computed get isInternalUser(): boolean {
    return this.appStore.authStore.isInternalUser;
  }

  @computed get statusOptions() {
    return this.taskStatuses.value.map((status) => ({
      label: status.name,
      value: status.id,
    }));
  }

  @computed get showTaskInfo() {
    const budgetItem = this.task.value?.ourWorkBudgetItem;
    return {
      duration: !!this.task.value?.from || !!this.task.value?.to,
      deadline: !!this.task.value?.deadline,
      tracked: this.isInternalUser && budgetItem,
      budget: this.isInternalUser && budgetItem,
    };
  }

  @computed get taskInfoItemsCount(): number {
    return Object.values(this.showTaskInfo).reduce((prev, curr) => {
      if (!curr) return prev;
      return prev + 1;
      // NOTE: 1 because we always show priority
    }, 1);
  }

  @computed get taskInfoColumnCount(): number {
    return this.taskInfoItemsCount >= 3 ? 3 : this.taskInfoItemsCount;
  }

  @computed get addTaskInfoSpacer(): boolean {
    return this.taskInfoItemsCount > 3;
  }

  @computed get taskInfoSpacerSpan(): number {
    return 6 - this.taskInfoItemsCount;
  }

  @computed get clientIds() {
    return this.task.value?.clients.map((client) => client.id) ?? [];
  }

  @computed get partnerIds() {
    return this.task.value?.partners.map((partner) => partner.id) ?? [];
  }

  @action.bound async fetchTask(
    { silently }: { silently?: boolean } = { silently: false },
  ) {
    if (!this.taskId.value) return;
    if (!silently) this.isLoading.on();

    let data: TaskQuery | TaskForScopedUsersQuery | null = null;
    let error: Error | readonly GraphQLError[] | null = null;

    if (this.isInternalUser) {
      const [queryData, queryError] = await this.internalUserTaskFetcher.fetch(
        {
          id: this.taskId.value,
        },
        undefined,
        silently,
      );
      data = queryData;
      error = queryError;
    } else {
      const [queryData, queryError] = await this.scopedUserTaskFetcher.fetch(
        {
          id: this.taskId.value,
        },
        undefined,
        silently,
      );
      data = queryData;
      error = queryError;
    }

    if (error || !data) return;

    this.task.set(
      new TaskModel(
        data.task,
        "taskPositionStat" in data ? data.taskPositionStat : undefined,
      ),
    );
    this.taskStatuses.set(data.taskStatuses);
    this.taskPriorities.set(data.taskPriorities);

    if (!silently) this.isLoading.off();
  }
}
