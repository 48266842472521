import { TaskModel } from "@src/components/widgets/Modals/ModalCommunication/models";
import { observer } from "mobx-react-lite";
import { TaskItemBase, TaskItemBaseProps } from "./TaskItemBase";

type TaskItemSubtaskProps = Omit<
  TaskItemBaseProps<TaskModel>,
  "content" | "title" | "task"
> & {
  isSubtask?: boolean;
  task: TaskModel;
};

export const TaskItemSubtask = observer(function TaskItemLinked(
  props: TaskItemSubtaskProps,
) {
  return (
    <TaskItemBase
      title={props.task?.name ?? ""}
      wrapperStyle={{
        gridTemplateColumns:
          "9rem 5rem 1fr max-content max-content max-content",
      }}
      content={() => <div />}
      onClick={props?.onClick}
      isSubtask
      {...props}
    />
  );
});
