import {
  Center,
  Spinner,
  Tab,
  TabList,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { COMMUNICATION_TABS } from "@src/constants/tasks";
import { useStore } from "@src/utils/hooks";
import useIntersectionObserver from "@src/utils/hooks/useIntersectionObserver";
import { observer } from "mobx-react-lite";
import { Fragment, useRef } from "react";

export const CommunicationTabParent = observer(
  function CommunicationTabParent() {
    const { taskDetailModalStore } = useStore();
    const commentsLoaderRef = useRef(null);
    const { communicationTabParentStore: store, activeTabId } =
      taskDetailModalStore;

    useIntersectionObserver({
      threshold: 0.25,
      target: commentsLoaderRef,
      onIntersect: () => {
        if (!store.pagination.hasNextPage && store.pagination.currentPage !== 0)
          return;
        if (store.commentsFetcher.isLoading.value) return;
        if (store.lastSeenCommentMutator.isLoading.value) return;
        store.fetchTaskComments(store.pagination);
      },
    });

    if (!COMMUNICATION_TABS.includes(activeTabId.value)) return;

    return (
      <Tabs p="0" index={store.selectedTab} onChange={store.setSelectedTab}>
        {store.availableTabs.length > 1 && (
          <TabList px="8">
            {store.availableTabs.map((tab, index) => (
              <Tab key={index}>{tab.label()}</Tab>
            ))}
          </TabList>
        )}
        <TabPanels>
          {store.availableTabs.map((tab, index) => (
            <Fragment key={`communication-tab-${index}`}>
              {tab.component}
            </Fragment>
          ))}
        </TabPanels>
        <Center ref={commentsLoaderRef} h="10" minH="10">
          {store.pagination.canLoadMore && <Spinner />}
        </Center>
      </Tabs>
    );
  },
);
